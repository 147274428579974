import Navbar from "../componentes/Navbar";
import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt, faExclamationTriangle,faCheckCircle } from "../../node_modules/@fortawesome/free-solid-svg-icons";
import '../elements/ResetPassword.css';
import axios from 'axios';
import '../componentes/GlobalVariables'

const baseURL = global.URLBack +"/login/recoverPassword/forgotPassword";
class ResetPassword extends React.Component {
  
  constructor(){
    super();
    
    this.state = {
      email: '',
      success: null,
      error: null,
      msg: ''
    };
    
  }
  handleChange = e =>{
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleSubmit = e => {    
    e.preventDefault();
    const config = {
      headers : { 
        'Content-Type': 'text/plain'
      }
    }
     axios.post(baseURL, this.state.email, config).then(
      res => {

        this.setState({msg: 'Correo para recuperar contraseña enviado exitosamente', success: true, error:false})
      }
    ).catch(
      err => {
        this.setState({msg: 'Correo electrónico inválido',error: true, success:false})
      }
    ) 
  };

  render()
  {
    const {email} = this.state;
    return (
      <div>
        <Navbar />
        <div id="container-rp" className="container">
          <section
            id="content-header-rp"
            className="content-header"

          >
            <h1 id="h1-grid">
              Reiniciar Contraseña
            <Breadcrumbs
              className="breadcrumbs"
              separator=">"
              aria-label="breadcrumb"
            >
              <Link
                className="link-rp"
                color="inherit"
                to="/"
                
              >
                <FontAwesomeIcon
                  className="icon"
                  icon={faTachometerAlt}
                 
                />
                Panel
              </Link>
              <Link
                className="link-rp"
                color="inherit"
                to="/resetPassword"
               
              >
                Reiniciar Contraseña
              </Link>
            </Breadcrumbs>
            </h1>
          </section>
          <section className="content">
          {this.state.error  === true && this.state.success  === false ? (<div className="alert alert-danger" role="alert"><FontAwesomeIcon icon ={faExclamationTriangle} /> 
  {this.state.msg} 
</div> ): null}
{this.state.error  === false && this.state.success  === true ? (<div className="alert alert-success" role="alert"><FontAwesomeIcon icon ={faCheckCircle} /> 
  {this.state.msg} 
</div> ): null}
            <div className="row">
              <div id="col-card-rp" className="col-md-offset-3 col-md-6" >
                <div className="card" >
                  <div className="card-body">
                    <h5 className="card-title">Recuperar Contraseña</h5>
                    <h6 className="card-subtitle mb-2 text-muted">Por favor, introduzca su dirección de correo electrónico</h6>
                    <form onSubmit={this.handleSubmit}>
                        <label>Correo electrónico</label>
                        <input className="form-control" name="email" placeholder="Email" type="email" required value={email}
                          onChange={this.handleChange}/>
                    <div className="content-btn-rp" >
                        <button onClick={this.handleClick} id="btn-rp" className="btn btn-success btn-block " type="submit" value="Reiniciar Contraseña" >Reiniciar Contraseña</button>
                        
                    </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div>
          </div>
        </div>
        <footer>
		© 2021 IvuControlPR 
	</footer>
      </div>
    );
  }
}
export default ResetPassword;
