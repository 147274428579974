import React from "react";
import Navbar from "../componentes/Navbar";
import Button from 'react-bootstrap/Button';
import '../elements/UserDashboard.css'
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Avatar from "../img/avatar.jpg";
import { Link } from 'react-router-dom';
import EditMerchant from "./EditMerchant";
import Alert from 'react-bootstrap/Alert';
import PayBillMerchant from "./PayBillMerchant";
import Swal from "sweetalert2";
import { Redirect } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

/**
 * Componente que enseña la pantalla principial del usuario
 */
class UserDashboard extends React.Component {
    constructor(props) {

        // Obtener la fecha actual
        const currentDate = new Date();
        super(props);
        this.state = {
            token: '',
            role: -1,
            user: {},
            loading: false,
            showEdit: false,
            merchant: {},
            bussines: [],
            error: false,
            perPage: 5, // Número de elementos por página
            totalPages: [],
            pageCount: 0,
            currentPage: 0,

            perPage2: 5, // Número de elementos por página
            totalPages2: [],
            pageCount2: 0,
            currentPage2: 0,
            activeB: [],
            inactiveB: [],
            additionalTerminal:0

        }
    }
    /**
     * Metodo encargado de obtener el token de usuario del localStorage y definirlo en el atributo de estado y de obtener la información
     * del usuario
     */
    async componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']

        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/profile/business`,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true
        }

        const config2 = {
            method: 'get',
            url: global.URLBack + `/dashBoard/profile/getInfoUser`,
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': 'Bearer ' + token
            },

            withCredentials: true
        }
        this.setState({ loading: true, role: role })
        await axios(config)
            .then(res => {
                var activeBussines = []
                activeBussines = res.data.filter(b => {
                    return b.active === 1
                })
                var inactiveBussines = []
                inactiveBussines = res.data.filter(b => {
                    return b.active === 0
                })
                this.setState({
                    bussines: res.data,
                    activeB: activeBussines,
                    inactiveB: inactiveBussines
                })

            }).catch(err => {
                this.setState({ loading: false, error: true })
                localStorage.removeItem('loggedIvuUser')
                //localStorage.removeItem('role')
                window.location.reload()
            })
        await axios(config2)
            .then(res => {
                this.setState({ loading: false })
                this.setState({
                    user: res.data.userInfo
                })
            }).catch(err => {
                this.setState({ loading: false, error: true })
                localStorage.removeItem('loggedIvuUser')
                //localStorage.removeItem('role')
                window.location.reload()
            })

    }

    /**
     * Metodo encargado de enseñar el modal de editar negocio
     */
    showEditModal = () => {
        this.setState({ showEdit: true, merchant: {} })
    }

    /**
    * Metodo utilizado para manejar la paginación de la tabla
    * @param {*} param0 Pagina seleccionada
    */
    handlePageChange = ({ selected }) => {
        const { perPage, activeB } = this.state;
        const offset = selected * perPage;
        const currentPageData = activeB.slice(offset, offset + perPage);

        this.setState({
            currentPage: selected,
            selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    /**
    * Metodo utilizado para manejar la paginación de la tabla
    * @param {*} param0 Pagina seleccionada
    */
    handlePageChange2 = ({ selected }) => {
        const { perPage2, inactiveB } = this.state;
        const offset = selected * perPage2;
        const currentPageData = inactiveB.slice(offset, offset + perPage2);

        this.setState({
            currentPage2: selected,
            selectedElement2: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    /**
     * Metodo encargado de desactivar un negocio
     * @param {*} merchantID 
     */
    inactivateBussines = (merchantID) => {
        Swal.fire({
            title: 'Esta seguro que desea desactivar el negocio?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Desactivar',
            cancelButtonText: "Cancelar",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                const config = {
                    method: 'put',
                    url: global.URLBack + `/dashBoard/profile/business/` + merchantID + '/0',
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    },
                    withCredentials: true
                }
                this.setState({ loading: true })
                axios(config).then(res => {
                    this.setState({ loading: false })
                    if (res.data) {
                        Swal.fire({
                            title: "Negocio desactivado exitosamente",
                            icon: "success"
                        })
                        const newBussinesActive = this.state.activeB;
                        const newBussinesInactive = this.state.inactiveB;
                        const bussinesModified = newBussinesActive.find((bussines) => bussines.MerchantID === merchantID);
                        const bussinesActive = newBussinesActive.filter((bussines) => bussines.MerchantID !== merchantID);
                        if (bussinesModified) {
                            bussinesModified.active = 0
                        }
                        newBussinesInactive.push(bussinesModified)
                        this.setState({ inactiveB: newBussinesInactive, activeB: bussinesActive })
                    } else {
                        Swal.fire({
                            title: "Ha ocurrido un erro al desactivar el negocio",
                            icon: "error"
                        })
                    }
                }).catch(err => {
                    Swal.fire({
                        title: "Ha ocurrido un erro al desactivar el negocio",
                        icon: "error"
                    })
                    this.setState({ loading: false, error: true })
                    localStorage.removeItem('loggedIvuUser')
                    //localStorage.removeItem('role')
                    window.location.reload()
                })
            }
        })

    }

    /**
     * Metodo encargado de activar un negocio
     * @param {*} merchantID identificador del negocio
     */
    activateBussines = (merchantID) => {
        const config = {
            method: 'put',
            url: global.URLBack + `/dashBoard/profile/business/` + merchantID + '/1',
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            withCredentials: true
        }
        this.setState({ loading: true })
        axios(config).then(res => {
            this.setState({ loading: false })
            if (res.data) {
                Swal.fire({
                    title: "Negocio reactivado exitosamente",
                    icon: "success"
                })
                const newBussinesActive = this.state.activeB;
                const newBussinesInactive = this.state.inactiveB;
                const bussinesModified = newBussinesInactive.find((bussines) => bussines.MerchantID === merchantID);
                const bussinesInactive = newBussinesInactive.filter((bussines) => bussines.MerchantID !== merchantID);
                if (bussinesModified) {
                    bussinesModified.active = 1
                }
                newBussinesActive.push(bussinesModified)
                this.setState({ inactiveB: bussinesInactive, activeB: newBussinesActive })
            } else {
                Swal.fire({
                    title: "Ha ocurrido un erro al reactivar el negocio",
                    icon: "error"
                })
            }
        }).catch(err => {
            Swal.fire({
                title: "Ha ocurrido un erro al reactivar el negocio",
                icon: "error"
            })
            this.setState({ loading: false, error: true })
            localStorage.removeItem('loggedIvuUser')
            //localStorage.removeItem('role')
            window.location.reload()
        })
    }


    /**
     *  Método para redirigir en caso de error
     * @returns Redirige a la página de inicio
     */
    redirectToRoot() {
        if (this.state.error) {
            // Redirige al usuario a la ruta raíz "/" si ocurre un error
            return <Redirect to="/" />;
        }
    }

    /**
     * Metodo encargado de validar si se habilita el boton de pago (Solo se habilita si faltan 10 días para el vencimiento de la suscripción)
     * @param {*} renovationDate fecha de renovación de la suscripción
     * @returns True en caso de que se pueda habilitar el pago, False en caso contrario
     */
    validatePayDate = (renovationDate) => {
        if (renovationDate !== undefined && renovationDate.length > 0) {
            // Convertir la fecha de renovación al formato de objeto Date
            const renewalDateObject = new Date(renovationDate);

            // Obtener la fecha actual
            const currentDate = new Date();

            // Calcular la fecha límite (30 días antes de la fecha de renovación)
            const thirtyDaysBeforeRenewal = new Date(renewalDateObject);
            thirtyDaysBeforeRenewal.setDate(renewalDateObject.getDate() - 30);
            return currentDate >= thirtyDaysBeforeRenewal
        }

        return true

    }

    /**
     * Metodo encargado de recargar la ventana en caso de que se haya realizado una edición exitosa de un negocio
     * @param {*} value Si es True entonces la edición fue exitosa, False en caso contrario
     */
    updateInfo = (value) => {

        if (value) {
            window.location.reload()
        }
    }

    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;
        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }


    /**
     * Metodo de renderizado
     * @returns Html que contiene la información del usuario encontrado junto con una tabla que contiene los negocios activos e inactivos
     */
    render() {
        const { perPage, currentPage, perPage2, currentPage2, activeB, inactiveB } = this.state

        const offset = currentPage * perPage;
        var currentPageDataActive = []
        if (activeB.length > 0) {
            currentPageDataActive = activeB.slice(offset, offset + perPage);
        } else {
            currentPageDataActive = activeB
        }

        const offset2 = currentPage2 * perPage2;
        var currentPageDataInactive = []
        if (inactiveB.length > 0) {
            currentPageDataInactive = inactiveB.slice(offset2, offset2 + perPage2);
        } else {
            currentPageDataInactive = inactiveB
        }

        var pageCountActive = Math.ceil(activeB.length / this.state.perPage);
        var pageCountInactive = Math.ceil(inactiveB.length / this.state.perPage2);
        // Obtén la fecha actual
        const fechaActual = new Date();

        // Convierte la fecha actual a un formato "yyyy/mm/dd"
        const fechaActualFormateada = `${fechaActual.getFullYear()}-${fechaActual.getMonth() + 1
            }-${fechaActual.getDate()}`;
        return (
            <React.Fragment>
               {this.renderNavbar()}
                {this.redirectToRoot()} {/* Llama al método de redirección */}
                <div className='mainn'>
                    {this.state.loading ?
                        <div className="sonn">
                            <div className="spinner"></div>
                        </div>

                        :
                        <div className="sonn row justify-content-center align-items-center">
                            <div className="col-12 col-md-3 mb-3 supMargin">
                                <Link to={"/dashboard/businessRegister"}>
                                    <Button variant="success" className="d-inline btnW" size="lg" >
                                        Registrar Nuevo Negocio
                                    </Button>
                                </Link>
                            </div>
                            <div className="col-12 col-md-3 mb-3 supMargin">
                                <Link to="/dashboard/payHistory">
                                    <Button style={{ backgroundColor: "#3262a8" }} className="d-inline btnW" size="lg" >
                                        Historial de pagos
                                    </Button>
                                </Link>
                            </div>
                            <div className="col-3 col-md-3 supMargin d-flex align-items-center justify-content-center" style={{ width: "20%" }}>
                                <img className="user-img" alt="Imagen Perfil" src={this.state.user.nameImage !== undefined && this.state.user.nameImage.length !== 0 ? global.URLBack + `/dashBoard/profile/downloadImageProfile/` + this.state.user.nameImage : Avatar} />

                            </div>

                            <div className="col-6 col-md-3 supMargin">
                                <div>
                                    <p><strong>Nombre: </strong>{this.state.user.userName + " " + this.state.user.lastName + " " + this.state.user.secondlastname}</p>
                                    <p><strong>Email: </strong> {this.state.user.email}</p>
                                    <p><strong>Socio Desde: </strong> {this.state.user.registerDate}</p>
                                    <p><strong>Ultima Visita: </strong> {this.state.user.lastLogin}</p>
                                    <Link to="/dashboard/profile">
                                        <Button variant="primary" >Editar Perfil</Button>
                                    </Link>
                                </div>
                            </div>
                            {this.state.bussines.length === 0 ?
                                <Alert variant="secondary">
                                    Aun no cuenta con negocios registrados
                                </Alert> :
                                <React.Fragment>
                                    <h3 className="mt-3" >CUENTAS ACTIVAS</h3>
                                    {activeB.length === 0 ?
                                        <Alert variant="secondary">
                                            No cuenta con negocios activos
                                        </Alert> :
                                        <React.Fragment>
                                            <Table responsive className="customTbl">
                                                <thead >
                                                    <tr>
                                                        <th scope="col">Negocio</th>
                                                        <th scope="col">Numero de Registro</th>
                                                        <th scope="col">Ultima transmición</th>
                                                        <th scope="col">Fecha de renovación</th>
                                                        <th scope="col">Fecha de pago</th>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentPageDataActive.map((aBussines, index) => (
                                                        <tr key={index}>
                                                            <td>{aBussines.bussinessname}</td>
                                                            <td>{aBussines.MerchantID}</td>
                                                            <td>{aBussines.lastTransaction}</td>
                                                            <td>{aBussines.renovationDate}</td>
                                                            <td>
                                                                {aBussines.paymentInProcess ?
                                                                    "Pago en proceso" :
                                                                    aBussines.lastPayment
                                                                }
                                                            </td>
                                                            <td>
                                                                <EditMerchant key={aBussines.MerchantID} merchantEdit={aBussines.MerchantID} updateInfo={this.updateInfo}></EditMerchant>
                                                            </td>
                                                            {this.validatePayDate(aBussines.lastPayment) && !aBussines.paymentInProcess ?
                                                                <td>
                                                                    {/*   <Button className="btnPago"><strong>HACER PAGO</strong></Button> */}

                                                                    <PayBillMerchant key={aBussines.MerchantID + index} merchant={aBussines.MerchantID} bussinessname={aBussines.bussinessname} buttonName={0} updateDates={this.updateInfo}></PayBillMerchant>


                                                                </td>
                                                                : null
                                                            }
                                                            <td><Button className="btnCancelar" onClick={() => this.inactivateBussines(aBussines.MerchantID)}><strong>DESACTIVAR</strong></Button></td>
                                                            <td>
                                                                <Link to={`/dashboard/monthActivity/${aBussines.MerchantID}`}>
                                                                    <Button className="btnMonthTrans">
                                                                        <strong>TRANSACCIONES MES ACTUAL</strong>
                                                                    </Button>
                                                                </Link>

                                                            </td>
                                                        </tr>
                                                    ))

                                                    }
                                                </tbody>
                                            </Table>
                                            <ReactPaginate
                                                pageCount={pageCountActive}
                                                onPageChange={this.handlePageChange}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                forcePage={this.state.currentPage} // Establece la página actual activa en el componente de paginación
                                            />
                                        </React.Fragment>
                                    }


                                    <h3 className="mt-3" >CUENTAS INACTIVAS</h3>
                                    {inactiveB.length === 0 ?
                                        <Alert variant="secondary">
                                            No cuenta con negocios inactivos
                                        </Alert> :
                                        <React.Fragment>
                                            <Table responsive className="customTbl">
                                                <thead >
                                                    <tr>
                                                        <th scope="col">Negocio</th>
                                                        <th scope="col">Numero de Registro</th>
                                                        <th scope="col">Ultima transmición</th>
                                                        <th scope="col">Fecha de renovación</th>
                                                        <th scope="col">Fecha de pago</th>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentPageDataInactive.map((inaBussines, index) => (
                                                        <tr key={index}>
                                                            <td>{inaBussines.bussinessname}</td>
                                                            <td>{inaBussines.MerchantID}</td>
                                                            <td>{inaBussines.lastTransaction}</td>
                                                            <td>{inaBussines.renovationDate}</td>
                                                            <td>
                                                                {inaBussines.paymentInProcess ?
                                                                    "Pago en proceso" :
                                                                    inaBussines.lastPayment
                                                                }
                                                            </td>
                                                            <td>
                                                                <EditMerchant key={inaBussines.MerchantID} merchantEdit={inaBussines.MerchantID} updateInfo={this.updateInfo}></EditMerchant>
                                                            </td>
                                                            <td>
                                                                {/*  <Button className="btnReactivar"><strong>REACTIVAR</strong></Button> */}
                                                                {(inaBussines.lastPayment.length === 0 || inaBussines.lastPayment <= fechaActualFormateada) && !inaBussines.paymentInProcess ?

                                                                    <PayBillMerchant key={inaBussines.MerchantID + index} merchant={inaBussines.MerchantID} bussinessname={inaBussines.bussinessname} buttonName={1} updateDates={this.updateInfo}></PayBillMerchant>
                                                                    :
                                                                    <Button className="btnReactivar" onClick={() => this.activateBussines(inaBussines.MerchantID)} ><strong>REACTIVAR</strong></Button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))

                                                    }
                                                </tbody>
                                            </Table>
                                            <ReactPaginate
                                                pageCount={pageCountInactive}
                                                onPageChange={this.handlePageChange2}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                forcePage={this.state.currentPage2} // Establece la página actual activa en el componente de paginación
                                            />
                                        </React.Fragment>
                                    }


                                </React.Fragment>

                            }

                        </div>
                    }
                </div>



            </React.Fragment >)
    }
}

export default UserDashboard;