import React from "react";
import {
  faExclamationTriangle,
  faEnvelope,
  faLock,
} from "../../node_modules/@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "../../node_modules/@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import axios from "axios";
import "./GlobalVariables";
import { withRouter } from "react-router-dom";
import imagenUser from "../img/avatar0.png";

const baseURL = global.URLBack + "/login/login/doLogin";

class FormLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        username: "",
        password: "",
      },
      error: "",
      isLogin: this.props.login2,
      token : '',
      role:'1'
    };
    this.handleChange = this.handleChange.bind(this);
  }


  validate = () => {
    let error = this.state.error;
    if (
      !this.state.user.username ||
      !this.state.user.password ||
      !this.state.user.username.includes("@") ||
      this.state.user.password.length < 4
    ) {
      error = "Email o Contraseña incorrectos";
      this.setState({ error });
      return false;
    }
    this.setState({ error: "" });
    return true;
  };

  handleChange(event) {
    let nombre = event.target.name;
    let value = event.target.value;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [nombre]: value,
      },
    }));

  }


  submitLogin(e) {
    
    e.preventDefault();
    let isValid = this.validate();
    //if (isValid) {
      
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      axios
        .post(baseURL, JSON.stringify(this.state.user), config)
        .then((res) => {
          this.setState({
            token: res.data.token,
            role: res.data.idrole,
          })
          this.setState({error: ""})
          this.setState({ isLogin: true})
          }).catch((err) => {
          this.setState({error: "Email o Contraseña incorrectos"})})
    //  }
    
  }

  render() {
    if (this.state.token !== '' && Number(this.state.role) === 1){
      localStorage.setItem('loggedIvuUser', this.state.token);
      //localStorage.setItem('role', this.state.role);
      window.location.replace("/dashboard")
    }
    else if(this.state.token !== '' && (Number(this.state.role) === 2 || Number(this.state.role) === 3)){
      localStorage.setItem('loggedIvuUser', this.state.token);
      //localStorage.setItem('role', this.state.role);
      window.location.replace("/dashboard/admin")
    }else if(this.state.token !== '' && Number(this.state.role) === 4){
      localStorage.setItem('loggedIvuUser', this.state.token);
      window.location.replace("/dashboard/reseller")
    }
    if(this.props.islogout2 === true){
      this.props.history.push("/")    
    }
    return (
      <div>
        {!this.props.login2 ? (
          <div>
            {this.state.error ? (
              <div className="alert alert-danger" role="alert">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                {this.state.error}
              </div>
            ) : null}
            <form onSubmit={this.handleSubmit}>
              <fieldset>
                <div id="div-email-fl">
                  <FontAwesomeIcon
                    className="icon-form"
                    icon={faEnvelope}
                    color="#555"
                  />
                  <input
                    type="email"
                    placeholder="E-mail"
                    name="username"
                    value={this.state.user.username}
                    onChange={this.handleChange.bind(this)}
                    required
                  />
                </div>
                <div id="div-password-fl">
                  <FontAwesomeIcon
                    className="icon-form"
                    icon={faLock}
                    color="#555"
                  />
                  <input
                    type="password"
                    name="password"
                    value={this.state.user.password}
                    onChange={this.handleChange.bind(this)}
                    placeholder="Contraseña"
                    required
                  />
                </div>
                <div id="div-login-fl">
                  <button
                    id="btn-login-fl"
                    type="submit"
                    className="btn btn-success "
                    onClick={this.submitLogin.bind(this)}
                  >
                    Iniciar Sesión
                  </button>
                </div>
              </fieldset>
            </form>
            <div id="div-rp-fl">
              <Link to="/resetPassword">¿Olvidaste tu contraseña?</Link>
            </div>
          </div>
        ) : (
          <div className="container-user-login">
            <div className="img-user-login">
              <img src={imagenUser} alt="imgUser" height="50vh"></img>
            </div>
            <div className="info-user-login">
              {" "}
              <h5>{this.props.name}</h5>
              <small>{this.props.lastLogin2}</small>
            </div>

            <div id="div-rp-fl">
              <button
                className="btn btn-light"
                type="submit"
                onClick={this.props.logout}
              >
                Cerrar sesión
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(FormLogin);
