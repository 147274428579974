import Navbar from "../componentes/Navbar";
import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt } from "../../node_modules/@fortawesome/free-solid-svg-icons";
import "../elements/ResetPassword.css";
import '../componentes/GlobalVariables'
import axios from "axios";


 const data = [
  {
    name: "",
    zipcode: "95963",
    isDefault: false,
    address: "AV 18 # 312",
    town: "ADJUNTAS",
    typeAddress: "Business",
    address2: "AV 18 # 3",
    state: "STATE",
    idAddress: 4
  }
];

const Danger = () =>{
  return(

      <div>
      <div>
      <button className="btn btn-light" disabled>Por Defecto</button>
      </div>
  </div>
      )
}

class Address extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data:data,
      businessName : "",
      bander: this.props.bander
  };
    this.eliminar=this.eliminar.bind(this);
}

  async componentDidMount(){
    const token = localStorage.getItem('loggedIvuUser')    
    const config = {
      method: 'get',
      url: global.URLBack + `/dashBoard/address/getAddress/` + this.props.match.params.idBusiness,
      headers: { 
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
      },
      withCredentials: true
    }

    await axios(config)
    .then(res => {      
      if(res.data.error){
        window.location.replace("/dashboard")
      }
      this.setState({
        data: res.data.addresses,
        businessName : res.data.businessName,
      })   
    }).catch(err => {
    })

  }

    
  setDefault=(elemento) => {
    const token = localStorage.getItem('loggedIvuUser')
    const config = {
      method: 'put',
      url: global.URLBack + `/dashBoard/address/setDefaultAddress/` + this.props.match.params.idBusiness,
      headers: { 
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
      }, 
      data: elemento.idAddress,
      withCredentials: true
    }

    axios(config)
    .then(res => {
      this.setState({data: res.data.addresses})          
    }).catch(err => {
  
    })
  }

  porDefecto = (elemento) => {
    var lista = this.state.data;
    lista.map((registro)=>{
      if (elemento.idAddress===registro.idAddress) {
        this.setState({isdefault: true})
      }
      return
    })
  }

    handleClick2 = e =>{
      e.preventDefault();
      window.location.reload()
      
    };
    eliminar=(elemento) => {
      const token = localStorage.getItem('loggedIvuUser')
      

      const config = {
        method: 'delete',
        url: global.URLBack + `/dashBoard/address/deleteAddress/` + this.props.match.params.idBusiness + `/`+ elemento.idAddress ,
        headers: {           
          'Authorization': 'Bearer ' + token
        },           
        withCredentials: true
      }
  
      axios(config)
      .then(res => {
        this.setState({data: res.data.addresses})
            
      }).catch(err => {
        
      })

    }
    
    render() {
    return (
      <div>
        <Navbar />
        <div id="container-rp" className="container">
          <section id="content-header-rp" className="content-header">
            <h1 id="h1-grid">
              Mi Dirección {this.state.businessName}
              <Breadcrumbs
                className="breadcrumbs"
                separator=">"
                aria-label="breadcrumb"
              >
                <Link className="link-rp" color="inherit" to="/dashboard">
                  <FontAwesomeIcon className="icon" icon={faTachometerAlt} />
                  Panel
                </Link>
                <Link
                  className="link-rp"
                  color="inherit"
                  onClick={this.handleClick2}
                  >
                  Mi dirección
                  
                </Link>
                
              </Breadcrumbs>
            </h1>
          </section>
          <div className="div-agregarAddress">
                <form className="btn-agregarAddress">
                  <Link to= {`/dashboard/address/addAddress/` + this.props.match.params.idBusiness }>
                    <button id="link-agregarAddress" type="button" className="btn btn-success">
                      {" "}
                      Agregar dirección
                    </button>
                  </Link>
                </form>
          </div>
          <section className="content">
              <div className="row">
            {this.state.data.map((elemento) => (
                <div className="col-6">
                  <div className="card">
                    <div className="card-body">
                  <h5 className="card-title">Dirección de {elemento.typeAddress}</h5>
                  <hr/>
                      <b>{elemento.name}</b>
                      <br />
                      {elemento.address}
                      <br />
                      {elemento.address2}
                      <br />
                      {elemento.town},{elemento.state}
                      {elemento.zipcode}
                      <br/>
                      <hr/>
                      <div> 
                      {elemento.isDefault ? <Danger/>: <Link id="btn1-address" to="#" className="btn btn-light"  onClick={()=>this.setDefault(elemento)}>Por defecto</Link>}
                          <Link id="btn2-address" to={`/dashboard/address/updateAddress/`+ elemento.idAddress} className="btn btn-primary" /* onClick={this.handleClick} */> Actualizar</Link>
                          {/*<Link id="btn3-address"to="#" className="btn btn-danger" onClick={()=>this.eliminar(elemento)}>Eliminar</Link>*/}
                          <Link id="btn4-address" to={`/dashboard/businessinfo/` + elemento.idAddress} className="btn btn-success">Información comercial</Link>
                      </div>
                    </div>
                  </div>
                </div>
            ))}
            </div>
          </section>
        </div>
        <footer>© 2021 IvuControlPR</footer>
     </div>
    );
  }
}

export default Address;
