import React from "react";
import Navbar from "../componentes/Navbar";
import '../componentes/GlobalVariables'
import axios from "axios";
import '../elements/Table.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFileDownload } from "../../node_modules/@fortawesome/free-solid-svg-icons"
import ModalEditUser from "./editUser";
import ReactPaginate from 'react-paginate';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

/**
 * Componente utilizado para listar todos los usuarios activos
 */
class Users extends React.Component {
  /**
    * Atributo de estado que contiene los atributos variables
    */
  state = {
    alluser: [],
    currentuser: [],
    currentPage: 0,
    perPage: 10, // Número de elementos por página
    totalPages: [],
    pageCount: 0,
    forms:
      [
        {
          idUser: '',
          idForm: '',
          username: '',
          phone: '',
          email: '',
          software: '',
          merchantName: '',
          merchantNumber: '',
          address: '',
          idFormSc: 0,
          idFormMC: 0,
          expirationDate: '',
          MerchantID: ''
        }
      ],
    token: '',
    sc: false,
    mc: false,
    role: -1,
    loading: false,
    searchText: "",
    merchantIDList: []
  };

  /**
    * Referencia a el campo que contiene la información que ingresa el usuario de busqueda
    */
  searchRef = React.createRef()

  /**
     * Metodo utilizado para obtener el valor de la busqueda que ingresó el usuario a partir de la referencia creada
     * @param {*} event 
     */
  handleSearchChange = (event) => {
    this.setState({ searchText: this.searchRef.current.value });
  }

  /**
   * Metodo utilizado para manejar la paginación de la tabla
   * @param {*} param0 Pagina seleccionada
   */
  handlePageChange = ({ selected }) => {
    const { perPage, forms } = this.state;
    const offset = selected * perPage;
    const currentPageData = forms.slice(offset, offset + perPage);

    this.setState({
      currentPage: selected,
      selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
    });
  };

  handleElementClick = (element) => {
    this.setState({ selectedElement: element });
  };

  /**
   * Metodo utilizado para actualizar un cliente dentro de la lista de clientes cuando se ha realizado una edición
   * @param {*} user cliente que ha sido editado
   */
  updateForms = (user) => {
    let index = this.searchIndex(user.merchantNumber)
    if (index !== undefined) {
      const updatedForms = [...this.state.forms];
      updatedForms[index] = user;
      this.setState({ forms: updatedForms });
    }

  }

  /**
   * Metodo utilizado para buscar el indice en el cual se encuentra un cliente en la lista
   * @param {*} merchantNumber número mercantil del cliente
   * @returns indice donde se encuentra el cliente en la lista
   */
  searchIndex = (merchantNumber) => {
    const { forms } = this.state
    let i = 0
    forms.forEach((user, index) => {
      if (Number(user.merchantNumber) === Number(merchantNumber)) {
        i = index
      }
    });
    return i
  }

  /**
   * Metodo utilizado para renderizar el contenido de la tabla de clientes
   * @param {*} filteredTerminals clientes a listar
   * @returns Html con el contenido de la tabla
   */
  renderTableData(filteredTerminals) {
    const { currentPage, perPage, forms } = this.state;
    const offset = currentPage * perPage;

    var currentPageData;
    if (this.state.searchText.length !== 0) {

      currentPageData = filteredTerminals.slice(offset, offset + perPage);


    } else {
      currentPageData = forms.slice(offset, offset + perPage);
    }



    return currentPageData.map((user, index) => (

      <tr key={index}>
        <React.Fragment>
          {user.username !== undefined && user.lastName !== undefined ?
            <td>{user.username + " " + user.lastName + " " + user.secondLastName}</td>
            :
            <td></td>

          }
          <td>{user.merchantName}</td>
          <td>{user.address}</td>
          <td className="pho">{user.phone}</td>
          <td >{user.email}</td>
          <td>{user.merchantID}</td>
          <td>{user.software}</td>
          <td>{user.expirationDate}</td>
          <td> {user.idFormSc !== 0 ? <button className="butF" title="Ver Formulario SC2916"
            type="submit"
            onClick={() => this.handleDownloadSC(user.idFormSc, user.merchantNumber)}>
            <FontAwesomeIcon className="icon1" icon={faFileDownload} />
          </button> : <FontAwesomeIcon icon={faFileExcel} title="No file " />}
          </td>
          <td> {user.idFormMC !== 0 ? <button className="butF" title="Ver Certificado Registro Comerciante"
            type="submit"
            onClick={() => this.handleDownloadMC(user.idFormMC, user.merchantNumber)}>
            <FontAwesomeIcon className="icon1" icon={faFileDownload} />
          </button> : <FontAwesomeIcon icon={faFileExcel} title="No file " />}
          </td>
          <td>
            {/* <button className="editButton" ><FontAwesomeIcon icon={faPen} size="lg"  /></button> */}
            <ModalEditUser key={user.idUser} merchantID={user.merchantID} handleDownloadMC={this.handleDownloadMC} updateForms={this.updateForms} handleDownloadSC={this.handleDownloadSC}
              tokenuser={this.state.tokenuser} merchantIDList={this.state.merchantIDList} />


          </td>
        </React.Fragment>



      </tr>
    ));
  }

  /**
   * Metodo encargado de obtener el token de usuario del localStorage y definirlo en el atributo de estado y obtener la lista de clientes activos
   */
  async componentDidMount() {
    const token = localStorage.getItem('loggedIvuUser')
    const [header, payload, signature] = token.split('.');

    // Decodificar la carga útil (payload) que contiene la información del usuario
    const decodedPayload = JSON.parse(atob(payload));

    const role = decodedPayload['role']
    this.setState({ token: token, role: role })
    const config = {
      method: 'get',
      url: global.URLBack + `/dashBoard/superUser/users`,
      headers: {
        'Authorization': 'Bearer ' + token
      },
      withCredentials: true
    }
    this.setState({ loading: true })
    await axios(config)
      .then(res => {
        this.setState({ loading: false })
        // Lógica para calcular el número total de páginas
        let idList = []
        res.data.forms.forEach(element => {
          idList.push(element.merchantID)
        });
        var pageC = Math.ceil(res.data.forms.length / this.state.perPage);
        this.setState({
          forms: res.data.forms,
          tokenuser: res.data.tokenUser,
          pageCount: pageC,
          merchantIDList: idList
        })
      }).catch(err => {
        this.setState({ loading: false })
        localStorage.removeItem('loggedIvuUser')
        //localStorage.removeItem('role')
      })
    /* const formSort = [...this.state.forms].sort((a, b) => (a.idUser > b.idUser ? 1 : a.idUser < b.idUser ? -1 : 0))
    this.setState({
      forms: formSort
    }) */
  }

  /*   pagination = () => {
      const data = this.state.forms
      let numPages = 0
      const rows = data.slice(this.state.currentPage * 10, this.state.currentPage * 10 + 10)
  
  
  
      for (let i = 0; i <= Math.floor((data.length - 1) / 10); i++) {
        this.state.totalPages.push(i)
  
      }
      numPages = this.state.totalPages.length
      let totalPages = new Set(this.state.totalPages)
      let pgs = [...totalPages]
      return { rows, pgs }
    } */

  /**
   * Metodo utilizado para descargar el formulario SC de un cliente
   * @param {*} idForm Identificador del formulario
   * @param {*} merchantNumber Número mercantil del cliente
   * @param {*} tokenuser Token del usuario (se obtiene al guardar un formulario)
   */
  handleDownloadSC(idForm, merchantNumber, tokenuser) {
    if (tokenuser !== undefined) {
      window.open(global.URLBack + `/dashBoard/superUser/getFormSC/` + idForm + '/' + tokenuser, "_blank")

    } else {
      window.open(global.URLBack + `/dashBoard/superUser/getFormSC/` + idForm + '/' + this.state.tokenuser, "_blank")

    }
  }

  /**
   * Metodo utilizado para descargar el formulario MC de un cliente
   * @param {*} idForm Identificador del formulario
   * @param {*} merchantNumber Número mercantil del cliente
   * @param {*} tokenuser Token del usuario (se obtiene al guardar un formulario)
   */
  handleDownloadMC(idForm, merchantNumber, tokenuser) {
    if (tokenuser !== undefined) {
      window.open(global.URLBack + `/dashBoard/superUser/getFormMC/` + idForm + '/' + tokenuser, "_blank")

    } else {
      window.open(global.URLBack + `/dashBoard/superUser/getFormMC/` + idForm + '/' + this.state.tokenuser, "_blank")

    }
  }

  renderNavbar() {
    // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
    const { role } = this.state;
    if (Number(role) !== -1) {
        return <Navbar SupUser={role} />;
    }

}


  /**
   * Metodo de renderizado
   * @returns Html que contine el elemento de busqueda para filtrar los clientes y la tabla de clientes activos
   */
  render() {
    //console.log(this.pagination().pgs.map(item => item +1))
    // Aplicar el filtro
    const filteredTerminals = this.state.forms.filter(client =>
      Object.entries(client).some(([key, value]) =>
        key !== 'merchantNumber' && String(value).toLowerCase().includes(this.state.searchText.toLowerCase())
      )
    );
    var pageC = this.state.pageCount
    if (this.state.searchText.length !== 0) {
      pageC = Math.ceil(filteredTerminals.length / this.state.perPage)
    }
    return (
      <div >
        {this.renderNavbar()}
        <div className='mainn'>
          <div className="sonn1">
            <p className='titulo'><strong>Clientes</strong></p>
          </div>

          <div className="sonn">
            {this.state.loading ?
              <div className="spinner"></div>
              :
              <React.Fragment>
                <Row className="g-2 search-bar align-items-center" >
                  <Col >
                    <p className="search-label"><strong>Buscar: </strong></p>
                  </Col>
                  <Col style={{ maxWidth: "500px" }} >
                    <Form>
                      <Form.Group className="mb-3" id="ControlInput1">
                        <Form.Label
                          className="mb-3"
                        >    </Form.Label>
                        <Form.Control type="text" placeholder="Ingrese su busqueda" ref={this.searchRef} onChange={this.handleSearchChange} />
                      </Form.Group>
                    </Form>

                  </Col>
                </Row>

                <table className="table">

                  <thead className="tr1">
                    <tr>

                      <th scope="col">Nombre</th>
                      <th scope="col">Negocio</th>
                      <th scope="col">Dirección</th>
                      <th scope="col">Teléfono</th>
                      <th scope="col">Correo</th>
                      <th scope="col">Número de comerciante</th>
                      <th scope="col">Software</th>
                      <th scope="col">Fecha de expiración</th>
                      <th scope="col">Formulario SC2916</th>
                      <th scope="col">Registro de Comerciante</th>
                      <th scope="col">Editar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderTableData(filteredTerminals)}
                  </tbody>
                </table>
                {/* Componente de paginación */}
                <ReactPaginate
                  pageCount={pageC}
                  onPageChange={this.handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                  forcePage={this.state.currentPage} // Establece la página actual activa en el componente de paginación
                />
              </React.Fragment>
            }

            {/*  <div className="pagination">{
              this.pagination()?.pgs.map((item, i) => (
                <a key={i} onClick={() => this.setState({ currentPage: item })}>{item + 1}</a>
              ))
            }</div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Users;
