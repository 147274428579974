import React from "react";
import Logo from "../img/logo.png";
import { faPencilAlt } from "../../node_modules/@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "../../node_modules/@fortawesome/react-fontawesome";
import SimpleMenu from "./MenuLogin.js";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import "./GlobalVariables";
import Sidebar from "../pages/Sidebar";
import { faUserTie, faUser } from "../../node_modules/@fortawesome/free-solid-svg-icons"


const baseURL = global.URLBack

/**
 * Componente que actua como barra de navegación que se encuentra en la parte superior
 */
class Navbar extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            isLogin: false,
            logout: false,
            role: null,
            user: {
                lastLogin: "",
                name: ""
            },
            superUser: props.SupUser
        };
        this.submitLoginOut = this.submitLoginOut.bind(this)
    }

    async componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')

        let config = {}
        let rol = this.state.superUser
        if (this.state.superUser === undefined) {

            if (token !== null) {
                const [header, payload, signature] = token.split('.');
                // Decodificar la carga útil (payload) que contiene la información del usuario
                const decodedPayload = JSON.parse(atob(payload));

                rol = decodedPayload['role']
            }
        }
        if (rol === 4) {
            config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                method: 'get',
                url: global.URLBack + `/login/login/getLastLoginReseller`,
                withCredentials: true
            }


        } else {
            config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                method: 'get',
                url: global.URLBack + `/login/login/getLastLogin`,
                withCredentials: true
            }

        }

        await axios(config)
            .then(res => {
                this.setState({
                    user: res.data
                })
                this.setState({ isLogin: true })
            }).catch(err => {
                if (err.response) {
                    if (err.response.data.ExpiredJwt) {
                        localStorage.removeItem('loggedIvuUser')
                        //localStorage.removeItem('role')
                        this.props.history.push('/')
                    }
                }
            })


    }

    submitLoginOut(e) {

        const token = localStorage.getItem('loggedIvuUser')

        e.preventDefault();
        const config = {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true
        };
        localStorage.removeItem('loggedIvuUser')
        //localStorage.removeItem('role')

        let url = ""
        if (Number(this.state.superUser) === 4) {
            url = '/login/login/logoutReseller'
        } else {
            url = '/login/login/logout'
        }

        axios
            .delete(baseURL + url, config)
            .then(
                (res) => {
                    this.setState({
                        logout: true
                    })
                    if (window.location.pathname === "/") {
                        window.location.reload()
                    }
                    else {
                        this.props.history.push('/')
                        window.location.reload()
                    }
                }
            )
            .catch(
        );

    }

    render() {
        var styleContainer = "container hidden-xs"

        if (this.state.superUser !== undefined && (Number(this.state.superUser) === 2 || Number(this.state.superUser) === 3)) {
            styleContainer = "container-fluid hidden-xs"
        }
        return (
            <div>
                {!this.state.isLogin ? <header id="main-header-nb" className="main-header">
                    <nav className="navbar navbar-static-top hidden-xs">

                        <div className="container hidden-xs">
                            <div className="navbar-header">
                                <Link to="/" className="navbar-brand hidden-xs">
                                    <img id="logo-nb" alt="logo" src={Logo} height="40px"></img >
                                </Link>
                            </div>
                            <div className="navbar-custom-menu">
                                <ul id="ul-nb" >
                                    <SimpleMenu login={this.state.isLogin}></SimpleMenu>
                                    <Link className="link-nb" to="/register">
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                        Registrarse
                                    </Link>
                                </ul>
                            </div>

                        </div>
                    </nav>

                </header> : <header id="main-header-nb" className="main-header">
                    <nav className="navbar navbar-static-top hidden-xs">

                        <div className={styleContainer}>


                            {this.state.superUser !== undefined && (Number(this.state.superUser) === 2 || Number(this.state.superUser) === 3) ?
                                <React.Fragment >
                                    < Sidebar />
                                    <Link to="/dashboard/admin" className="navbar-brand hidden-xs ">
                                        <img id="logo-nb" alt="logo" src={Logo} height="40px"></img >
                                    </Link>
                                </React.Fragment >
                                :
                                <React.Fragment>
                                    {Number(this.state.superUser) === 1 ?
                                        <Link to="/" className="navbar-brand hidden-xs">
                                            <img id="logo-nb" alt="logo" src={Logo} height="40px"></img >
                                        </Link> :
                                        <Link to="/" className="navbar-brand hidden-xs">
                                            <img id="logo-nb" alt="logo" src={Logo} height="40px"></img >
                                        </Link>

                                    }
                                </React.Fragment>

                            }


                            <div className="navbar-custom-menu">
                                <ul id="ul-nb" >
                                    {this.state.superUser !== undefined && (Number(this.state.superUser) === 2 || Number(this.state.superUser) === 3) ?
                                        <FontAwesomeIcon icon={faUserTie} style={{ height: "20px", width: "20px" }} />
                                        : <FontAwesomeIcon icon={faUser} style={{ height: "20px", width: "20px" }} />}

                                    <SimpleMenu login={this.state.isLogin}
                                        nombre={this.state.user.name}
                                        lastLogin={this.state.user.lastLogin}
                                        handleclick2={this.submitLoginOut}></SimpleMenu>
                                </ul>
                            </div>

                        </div>
                    </nav>

                </header>}

            </div>

        );

    }

}

export default withRouter(Navbar);