import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import React from "react";
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';

/**
 * Componente que representa el modal de cambio de cuenta
 */
class CheckChange extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showCheckModal: false,
            validated: false,
            validForm: true,
            accountInfo: {
                merchantName: props.merchantName,
                merchantId: props.merchantId,
                id_cv: undefined,
                accountNameBank: "",
                accountNumberBank: "",
                routeNumberBank: "",
                currentAccountNameBank: "",
                currentAccountNumberBank: ""
            },
            void: "",
            token: "",
            loading: false,
            auxMN: props.merchantName,
            auxMI: props.merchantId
        };
    }

    /**
     * Carga el token del local storage
     */
    componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        this.setState({ token: token })
    }

    /**
     * Metodo utilizado para desplegar u ocultar el modal
     * @param {*} value Si es True entonces se despliega el modal, en caso contrario se oculta
     */
    checkModal = (value) => {
        this.setState({
            showCheckModal: value,
            validated: false
        })
        this.limpiar()
    }

    /**
     * Metodo que actualiza el valor de los atributos del estado de acuerdo a lo ingresado en el formulario
     * @param {*} e evento del input
     */
    handleChange = (e) => {
        var { name, value } = e.target;
        this.setState((prevState) => ({
            accountInfo: { ...prevState.accountInfo, [name]: value }
        }));

        this.valdidateForm(e)
    };

    /**
     * Metodo utilizado para validar el formulario 
     * @param {*} e Elemento que disparó el evento
     * @returns True si el formulario se encuentra correcto, False en caso contrario.
     */
    valdidateForm = (e) => {
        const form = document.getElementById("formAccount");
        this.setState({
            validated: true
        })
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                validForm: false
            })
            return false
        } else {
            this.setState({
                validForm: true
            })
            return true
        }
    }

    /**
     * Metodo utilizado para limpiar todos los parametros de accountInfo
     */
    limpiar() {
        this.setState({
            accountInfo: {
                merchantName: this.state.auxMN,
                merchantId: this.state.auxMI,
                id_cv: undefined,
                accountNameBank: "",
                accountNumberBank: "",
                routeNumberBank: "",
                currentAccountNameBank: "",
                currentAccountNumberBank: ""
            },
            void: ""
        })
    }

    /**
     * Metodo utilizado para realizar el cambio de cuenta
     * @param {*} event evento submit
     */
    handleSubmitModal = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const formDataCV = new FormData();

            formDataCV.append("CV", this.state.void);
            const ChequeVoidConfig = {
                method: 'post',
                url:
                    global.URLBack +
                    `/dashBoard/business/saveFormCV/`,
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                data: formDataCV
            }
            this.setState({ loading: true })
            axios(ChequeVoidConfig).then(response => {
                if (response) {
                    this.setState((prevState) => ({
                        accountInfo: { ...prevState.accountInfo, id_cv: response.data.id_cv }
                    }), () => {
                        let config = {
                            method: 'post',
                            url: global.URLBack + `/dashBoard/business/accountChange`,
                            headers: {
                                'Authorization': 'Bearer ' + this.state.token
                            },
                            withCredentials: true,
                            data: this.state.accountInfo
                        }
                        axios(config).then(res => {
                            this.setState({ loading: false })
                            if (res) {
                                Swal.fire({
                                    title: res.data.msg,
                                    icon: 'success'
                                })
                                this.props.updateVoidDate(true)
                                this.checkModal(false)
                            }
                        }).catch(error => {
                            this.props.updateVoidDate(false)
                            this.setState({ loading: false })
                            Swal.fire({
                                title: "Ha ocurrido un error al momento de realizar el cambio de cuenta",
                                text: "Por favor intentelo más tarde",
                                icon: "error"
                            })
                            this.checkModal(false)
                        })
                    })
                }
            }).catch(err => {
                this.props.updateVoidDate(false)
                this.setState({ loading: false })
                Swal.fire({
                    title: "Ha ocurrido un error al momento de cargar el archivo cheque void",
                    text: "Por favor intentelo más tarde",
                    icon: "error"
                })
                this.checkModal(false)
            })
        }
        this.setState({ validated: true })
    }

    /**
     * Metodo utilizado para almacenar el archivo que se está cargando en un atributo
     * @param {*} e evento input file
     */
    handleFiles = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }

    /**
     * Metodo de renderizado
     * @returns html que contiene el botón que despliega el modal y el modal
     */
    render() {
        const { accountInfo } = this.state
        return (
            <React.Fragment>
                <Button variant="primary" className='mt-1' onClick={() => this.checkModal(true)}>Cambiar De Cuenta</Button>
                <Modal show={this.state.showCheckModal} onHide={() => this.checkModal(false)} dialogClassName="my-modal" backdrop="static">
                    <Form noValidate validated={this.state.validated} id="formAccount"  >
                        <Modal.Header closeButton>
                            <Modal.Title>Cambiar de Cuenta</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            {this.state.loading &&
                                <div className="overlay">
                                    <div className="spinner2"></div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-6" style={{ backgroundColor: "#f2e383" }}>
                                    <h2>CUENTA ACTUAL</h2>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Nombre de Cuenta *</Form.Label>
                                        <Form.Control type="text" value={accountInfo.currentAccountNameBank} onChange={this.handleChange} required name="currentAccountNameBank" />
                                        <Form.Control.Feedback type="invalid" >
                                            Por favor ingrese el nombre de la cuenta
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Ultimos 4 digitos de la Cuenta *</Form.Label>
                                        <Form.Control type="text" value={accountInfo.currentAccountNumberBank} onChange={this.handleChange} maxLength={4} minLength={4} required name="currentAccountNumberBank" />
                                        <Form.Control.Feedback type="invalid" >
                                            Por favor ingrese los últimos 4 digitos de la cuenta
                                        </Form.Control.Feedback>
                                    </Form.Group>




                                </div>

                                <div className="col-6" style={{ backgroundColor: "#37bfbd" }}>
                                    <h2>CUENTA NUEVA</h2>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Nombre de Cuenta *</Form.Label>
                                        <Form.Control type="text" required name="accountNameBank" value={accountInfo.accountNameBank} onChange={this.handleChange} />
                                        <Form.Control.Feedback type="invalid" >
                                            Por favor ingrese el nombre de la cuenta
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Numero de Cuenta *</Form.Label>
                                        {/*  <Form.Control type="text" required name="accountNumberBank" maxLength={16} minLength={16} value={accountInfo.accountNumberBank} onChange={this.handleChange} /> */}
                                        <InputMask className="inputMask"
                                            onChange={this.handleChange}
                                            value={accountInfo.accountNumberBank}
                                            name="accountNumberBank"
                                            type="text"
                                            placeholder="Número de cuenta"
                                            mask="9999999999999999"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            Por favor ingrese el número de cuenta
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Numero de ruta y transito *</Form.Label>
                                        {/* <Form.Control type="text" required name="routeNumberBank" maxLength={9} minLength={9} value={accountInfo.routeNumberBank} onChange={this.handleChange} /> */}
                                        <InputMask className="inputMask"
                                            onChange={this.handleChange}
                                            name="routeNumberBank"
                                            type="text"
                                            placeholder="Número de ruta"
                                            mask="999999999"
                                            value={accountInfo.routeNumberBank}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            Por favor ingrese el número de ruta y transito
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </div>

                            </div>
                            <div className='row'>
                                <div className='col-6'>

                                </div>
                                <div className='col-6'>
                                    <div className="checkbox-item mt-3 mr-3">
                                        <label className="container-check">
                                            <input type="checkbox" readOnly checked={this.state.void.length !== 0} />
                                            <div className="checkmark"></div>
                                        </label>
                                        <span>Cheque Void</span>
                                        <Button variant="primary" className='mt-1'> <label >Subir
                                            <input className="fales"
                                                type="file"
                                                id="file1"
                                                name="void"
                                                placeholder="Cargar"
                                                accept="application/pdf"
                                                onChange={(e) => this.handleFiles(e)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese el archivo
                                            </Form.Control.Feedback>
                                        </label>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.checkModal(false)}>
                                Cancelar
                            </Button>
                            <Button variant="primary" type="submit" onClick={(e) => this.handleSubmitModal(e)}>
                                Guardar
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </React.Fragment>
        )
    }
}

export default CheckChange;