import React from "react";
import Navbar from "../componentes/Navbar";
import axios from "axios";
import ReactPaginate from 'react-paginate';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import '../elements/PriceAndServices.css'
import Swal from "sweetalert2";
import ServiceRegister from "./ServiceRegister";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "../../node_modules/@fortawesome/free-solid-svg-icons"

class PricesAndServices extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            role: -1,
            token: "",
            currentPage: 0,
            perPage: 10, // Número de elementos por página
            pageCount: 0,
            loading: false,
            services: [],
            additionalTerminalsPrice: {
                terminals2to5: 0,
                referralPayment2to5: 0,
                terminals6to9: 0,
                referralPayment6to9: 0,
                terminals10: 0,
                referralPayment10: 0,
            },
            validForm: true,
            validated: false,
            paymentMethods: [],
        }
    }

    componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role: role })

        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/service`,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true
        }
        this.setState({ loading: true })
        axios(config).then(response => {
            this.setState({ loading: false })
            if (Number(response.status) === 200) {
                var pageC = Math.ceil(response.data.length / this.state.perPage);
                this.setState({ services: response.data, pageCount: pageC })
                const config3 = {
                    method: 'get',
                    url: global.URLBack + `/dashBoard/superUser/paymentMethods`,
                }

                axios(config3).then(response2 => {
                    this.setState({ paymentMethods: response2.data })
                }).catch(error => {
                })
            }
        }).catch(err => {

        })

        const aditionalTerminalsConfig = {
            method: "get",
            url: global.URLBack + `/query/register/aditionalTerminals`,
          };
      
          axios(aditionalTerminalsConfig).then(response => {
            if (Number(response.status) === 200) {
              this.setState({ additionalTerminalsPrice: response.data })
            }
          }).catch(err => {
      
          })
    }

    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;

        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }

    /**
* Metodo utilizado para manejar la paginación de la tabla
* @param {*} param0 Pagina seleccionada
*/
    handlePageChange = ({ selected }) => {
        this.setState({
            currentPage: selected,
            selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    /**
* Metodo utilizado para validar el formulario 
* @param {*} e Elemento que disparó el evento
* @returns True si el formulario se encuentra correcto, False en caso contrario.
*/
    valdidateForm = (e) => {
        const form = document.getElementById("formUser");
        this.setState({
            validated: true
        })
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
         
            this.setState({
                validForm: false
            })
            return false
        } else {
       
            this.setState({
                validForm: true
            })
            return true
        }
    }

    /**
     * Metodo que actualiza el valor de los atributos del estado de acuerdo a lo ingresado en el formulario
     * @param {*} e evento del input
     */
    handleChange = (e) => {
        var { name } = e.target;
        let value = e.target.value


        this.setState((prevState) => ({
            additionalTerminalsPrice: { ...prevState.additionalTerminalsPrice, [name]: value }
        }));

        this.valdidateForm(e)

    };

    handleChange2 = (index) => (event) => {
        // Crea una copia del array paymentMethods
        const updatedPaymentMethods = [...this.state.paymentMethods];

        // Modifica el atributo status del objeto en la posición index
        updatedPaymentMethods[index] = {
            ...updatedPaymentMethods[index],
            status: event.target.checked,
        };

        // Actualiza el estado con la nueva copia
        this.setState({ paymentMethods: updatedPaymentMethods });

        const config = {
            method: 'put',
            url: global.URLBack + `/dashBoard/superUser/paymentMethods/${updatedPaymentMethods[index].code}/status/${event.target.checked}`,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            withCredentials: true
        }
        axios(config).then(response => {
            if (Number(response.status) == 200) {
                Swal.fire({
                    title: "Estado actualizado correctamente",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false,
                    timerProgressBar: true
                })
            } else {
                Swal.fire({
                    title: "Ha ocurrido un error al actualizar el estado",
                    icon: "error",
                    timer: 2000,
                    showConfirmButton: false,
                    timerProgressBar: true
                })
            }
        }).catch(err => {
            Swal.fire({
                title: "Ha ocurrido un error al actualizar el estado",
                icon: "error",
                timer: 2000,
                showConfirmButton: false,
                timerProgressBar: true
            })
        })
    };

    applyChangesPrices = (e) => {

        e.preventDefault();
        e.stopPropagation();

        const config = {
            method: 'put',
            url: global.URLBack + `/dashBoard/superUser/aditionalTerminals`,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            data: this.state.additionalTerminalsPrice,
            withCredentials: true
        }

        axios(config).then(response => {
            if (Number(response.status) == 200) {
                Swal.fire({
                    title: "Valores actualizados correctamente",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false,
                    timerProgressBar: true
                })
            } else {
                Swal.fire({
                    title: "Ha ocurrido un error al intentar actualizar los valores",
                    text: "Por favor intentelo más tarde",
                    icon: "error"
                })
            }
        }).catch(err => {
            Swal.fire({
                title: "Ha ocurrido un error al intentar actualizar los valores",
                text: "Por favor intentelo más tarde",
                icon: "error"
            })
        })
    }

    updateServices = (service) => {
        const { services } = this.state;
        const serviceExistenteIndex = services.findIndex(
            (r) => r.serviceId === service.serviceId
        );

        if (serviceExistenteIndex !== -1) {
            const nuevaLista = [...services];
            nuevaLista[serviceExistenteIndex] = service;
            this.setState({ services: nuevaLista });
        } else {
            this.setState({ services: [service, ...services] });
        }

    }

    deleteService = (idService) => {
        Swal.fire({
            title: "¿Está seguro que desea eliminar el servicio?",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Aceptar",
            reverseButtons: true
        }).then((response) => {
            if (response.isConfirmed) {
                const config = {
                    method: 'delete',
                    url: global.URLBack + `/dashBoard/service/${idService}`,
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    },
                    withCredentials: true
                }
                this.setState({ loading: true })
                axios(config).then(res => {
                    this.setState({ loading: false })
                    if (res) {
                        Swal.fire({
                            title: "Servicio eliminado correctamente",
                            icon: "success"
                        })
                        this.setState({ services: this.state.services.filter(service => service.serviceId !== idService) })
                    }
                }).catch(error => {
                    this.setState({ loading: false })
                    Swal.fire({
                        title: "Ha ocurrido un error al eliminar el servicio",
                        icon: "error"
                    })
                })
            }
        })
    }

    render() {

        const { currentPage, perPage, services, role } = this.state;
        const offset = currentPage * perPage;
        var pageC = this.state.pageCount
        var currentPageData = []
        if (services.length > 0) {
            currentPageData = services.slice(offset, offset + perPage);
        } else {
            currentPageData = services
        }


        return (
            <React.Fragment>
                {this.renderNavbar()}
                <div className='mainn'>
                    <div className="sonn1" style={{ height: "50px" }}>
                        <p className='titulo'><strong>Precios y servicios</strong></p>
                    </div>
                    <div className="sonn">
                        {this.state.loading ?
                            <div className="spinner"></div>
                            :
                            <React.Fragment>
                                <div className="row mt-5">
                                    <div className="col-6">
                                        <h2>Servicios Ofrecidos</h2>

                                        {services.length <= 0 ?
                                            <Alert variant="primary" className="mt-5">
                                                Aun no cuenta con servicios registrados
                                            </Alert> :
                                            <React.Fragment>
                                                {Number(role) !== 3 ?
                                                    <ServiceRegister edit={false} updateServices={this.updateServices}></ServiceRegister>
                                                    : null
                                                }
                                                <Table responsive>

                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th scope="col">Nombre</th>
                                                            <th scope="col">Precio</th>
                                                            <th scope="col">Duración (Días)</th>
                                                            <th scope="col">Pago Referido</th>
                                                            <th>Editar</th>
                                                            {Number(role) !== 3 ?
                                                                <th>Eliminar</th> : null
                                                            }

                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {currentPageData.map((service, index) => (
                                                            <tr key={index}>
                                                                <td>{service.serviceName}</td>
                                                                <td>$ {service.serviceValue}</td>
                                                                <td>{service.duration}</td>
                                                                <td>$ {service.referralPayment}</td>
                                                                <td> <ServiceRegister key={service.serviceId} edit={true} serviceObj={service} updateServices={this.updateServices}></ServiceRegister></td>
                                                                {Number(role) !== 3 ?
                                                                    <td><div onClick={() => this.deleteService(service.serviceId)} style={{ cursor: "pointer" }} ><FontAwesomeIcon icon={faTrash} style={{ color: "#d92626", }} /></div></td>
                                                                    : null
                                                                }
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <div className="d-flex justify-content-center">
                                                    <ReactPaginate
                                                        pageCount={pageC}
                                                        onPageChange={this.handlePageChange}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        forcePage={this.state.currentPage}
                                                    />
                                                </div>


                                            </React.Fragment>

                                        }
                                    </div>
                                    <div className="col-6">
                                        <h2>Terminales Adicionales</h2>
                                        <Form noValidate className="form-style ms-3" id="formUser" onSubmit={this.applyChangesPrices} validated={this.state.validated} >
                                            <Form.Group as={Row} className="mb-3">

                                                <Form.Label column sm="2">
                                                    <br></br>
                                                    Terminales 2-5
                                                </Form.Label>
                                                <Col sm="10" className="w-25">
                                                    <label>Costo:</label>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control type="number" placeholder="costo" value={this.state.additionalTerminalsPrice.terminals2to5} required name="terminals2to5" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3}/>
                                                        <Form.Control.Feedback type="invalid" >
                                                            Por favor ingrese el valor de la terminal adicional de 2 a 5 terminales
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                                <Col sm="10" className="w-25">
                                                    <label>Pago Referido:</label>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control type="number" placeholder="costo" value={this.state.additionalTerminalsPrice.referralPayment2to5} required name="referralPayment2to5" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3}/>
                                                        <Form.Control.Feedback type="invalid" >
                                                            Por favor ingrese el valor de pago referifo de 2 a 5 terminales
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">

                                                <Form.Label column sm="2">
                                                    Terminales 6-9
                                                </Form.Label>
                                                <Col sm="10" className="w-25">
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control type="number" placeholder="costo" required value={this.state.additionalTerminalsPrice.terminals6to9} name="terminals6to9" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3}/>
                                                        <Form.Control.Feedback type="invalid" >
                                                            Por favor ingrese el valor de la terminal adicional de 6 a 9 terminales
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                                <Col sm="10" className="w-25">
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control type="number" placeholder="costo" value={this.state.additionalTerminalsPrice.referralPayment6to9} required name="referralPayment6to9" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3}/>
                                                        <Form.Control.Feedback type="invalid" >
                                                            Por favor ingrese el valor de pago referifo de 6 a 9 terminales
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">

                                                <Form.Label column sm="2">
                                                    Terminales 10 o más
                                                </Form.Label>
                                                <Col sm="10" className="w-25">
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control type="number" placeholder="costo" value={this.state.additionalTerminalsPrice.terminals10} required name="terminals10" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3}/>
                                                        <Form.Control.Feedback type="invalid" >
                                                            Por favor ingrese el valor de la terminal adicional de 10 o más terminales
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                                <Col sm="10" className="w-25">
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control type="number" placeholder="costo" value={this.state.additionalTerminalsPrice.referralPayment10} required name="referralPayment10" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3}/>
                                                        <Form.Control.Feedback type="invalid" >
                                                            Por favor ingrese el valor de pago referifo de 10 o más terminales
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Button variant="warning" type="submit" disabled={Number(role)===3}> Aplicar en todas las cuentas</Button>
                                        </Form>
                                        <h2 className="mt-3">Metodos de Pago</h2>
                                        <ul className="mt-3">
                                            {this.state.paymentMethods.map((method, index) => (
                                                <li key={index} style={{ listStyleType: 'none' }}>

                                                    <Form.Group as={Row} className="mb-3">

                                                        <Form.Label column sm="2">
                                                            {method.name}
                                                        </Form.Label>
                                                        <Col sm="10" className="w-25">
                                                            <label className="switch-2">
                                                                <input type="checkbox" className="toggle" checked={method.status} name="status" onChange={this.handleChange2(index)} disabled={Number(role)===3}></input>
                                                                <span className="slider-2"></span>
                                                                <span className="card-side"></span>
                                                            </label>
                                                        </Col>
                                                    </Form.Group>
                                                </li>
                                            ))

                                            }
                                        </ul>
                                    </div>
                                </div>

                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PricesAndServices;