import slide1 from '../img/PuertoRico1.png'
import slide2 from '../img/PuertoRico2.png'
import slide3 from '../img/PuertoRico3.png'
import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const items = [
  {
    src: slide1,
    altText: "Hecho en Puerto Rico",
    altText2: "Para Puerto Rico", 
    
  },
  {
    src: slide2,
    altText: 'IvuControlPR',
    altText2: 'Fácil',
    altText3: 'Simple',
    altText4: 'Eficiente',

  },
  {
    src:slide3,
    altText: 'Relájate',
    altText2: 'Te cubrimos',

  }
];

const Banner = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }


  const slides = items.map((item) => {
    return (
      
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div id="Carousel-div" style={{backgroundImage: `url(${item.src})`}}>
          <span>
            <h1 id="Carousel-h1">{item.altText}<br/>{item.altText2}<br/>{item.altText3}<br/>{item.altText4}</h1>
          </span>
        </div>
      </CarouselItem>
     
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default Banner; 


