import React from "react";
import Navbar from "../componentes/Navbar";
import Form from 'react-bootstrap/Form';
import '../elements/Table.css'
import '../elements/MonthActivity.css'
import Table from 'react-bootstrap/Table';
import axios from "axios";
import ReactPaginate from 'react-paginate';
import Alert from 'react-bootstrap/Alert';

/**
 * Componente que enseña la actividad de los últimos 90 días de un cliente
 */
class Last90daysActivity extends React.Component {

    /**
     * Atributo de estado que contiene los atributos variables
     */
    state = {
        token: '',
        role: -1,
        searchText: "",
        currentPage: 0,
        perPage: 10, // Número de elementos por página
        pageCount: 0,
        client: {},
        transactionsList: [],
        loading: false,
        searching: false,
        users: [],
        searchQuery: '',
        searchResults: [],
        showOptions: false
    };

    /**
     * Referencia a el campo que contiene la información que ingresa el usuario de busqueda
     */
    searchRef = React.createRef()

    /**
     * Metodo encargado de obtener el token de usuario del localStorage y definirlo en el atributo de estado
     */
    componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role:role })
    }

    /**
     * Metodo utilizado para obtener el valor de la busqueda que ingresó el usuario a partir de la referencia creada
     * @param {*} event 
     */
    handleSearchChange = (event) => {
        this.setState({ searchText: this.searchRef.current.value });
    }

    /**
     * Metodo utilizado para manejar la paginación de la tabla
     * @param {*} param0 Página seleccionada
     */
    handlePageChange = ({ selected }) => {
        this.setState({
            currentPage: selected,
            selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    /**
     * Metodo utilizado para realizar la busqueda de las transacciones de los ultimos 90 dias buscando a el usuario a partir de lo ingresado 
     * en el campo de busqueda
     */
    searchClient() {
        this.setState({ searching: true })
        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/superUser/transactionsLast90Days?filtro=` + this.state.searchText,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            withCredentials: true
        }
        this.setState({ loading: true })
        axios(config)
            .then(res => {
                this.setState({ loading: false })
                var pageC = Math.ceil(res.data.transacciones.length / this.state.perPage);
                this.setState({
                    client: res.data,
                    pageCount: pageC,
                    transactionsList: res.data.transacciones
                })

            }).catch(err => {
                this.setState({ loading: false })
            })
    }

    /**
     * Metodo utilizado para listar posibles usuarios que coincidan con un criterio de busqueda ingresado por el usuario
     * para que posteriormente los seleccione y con ese usuario seleccionado se obtenga la información del cliente y su historial de pagos
     * @param {*} event Input que contiene el criterio de busqueda
     */
    handleInputChange = (event) => {
        const query = event.target.value;
        let showOptions = true
        var filteredUsers = []
        this.setState({ searchQuery: query });
        //TODO:AQUI SE COLOCA EL SERVICIO DEL BACKEND
        if (query.trim().length !== 0) {

            const config = {
                method: 'get',
                url: global.URLBack + `/dashBoard/superUser/usersFilter?filter=` + query,
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
                withCredentials: true
            }
            axios(config)
                .then(res => {
                    this.setState({ loading: false })
                    filteredUsers = res.data.forms
                    if (filteredUsers.length === 0 || query === "") {
                        showOptions = false
                    }
                    this.setState({ searchResults: filteredUsers, showOptions: showOptions });
                }).catch(err => {
                    this.setState({ loading: false })

                })


        } else {
            this.setState({ searchResults: [], showOptions: false });
        }

    };

    /**
     * Metodo utilizado para asignar cual fue el cliente seleccionado para obtener la información 
     * @param {*} option objeto del cliente seleccionado
     */
    handleSelectOption = (option) => {
        this.setState({ showOptions: false, searchText: option.merchantID }, () => {
            this.searchClient()
        });


    };

    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;
        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }



    /**
     * Metodo de renderizado
     * @returns Html que contiene la información del usuario encontrado junto con una tabla que contiene las transacciones de los ultimos 90 dias
     */
    render() {
        const { currentPage, perPage, transactionsList, searchQuery, searchResults, showOptions } = this.state;
        const offset = currentPage * perPage;
        var pageC = this.state.pageCount
        var currentPageData = []
        if (transactionsList.length > 0) {
            currentPageData = transactionsList.slice(offset, offset + perPage);
        } else {
            currentPageData = transactionsList
        }
        return (
            <React.Fragment>
                {this.renderNavbar()}
                <div className='mainn'>
                    <div className="sonn1">
                        <p className='titulo'><strong>Actividad de los últimos 90 días</strong></p>
                    </div>
                    <div className="sonn">
                        {this.state.loading ?
                            <div className="spinner"></div>
                            :
                            <React.Fragment>
                                <Alert variant="info" className="infoAlert">
                                    Recuerde que puede realizar la busqueda ingresando el nombre del cliente, nombre del negocio, el ID del merchant o el número del merchant.
                                </Alert>
                                <div className="row">
                                    <div className="col-1">
                                        <h4 className="mt-3" >
                                            Nombre:
                                        </h4>
                                    </div>
                                    <div className="col-10">
                                        <Form.Group className="mt-3" >
                                            <Form.Control
                                                type="text"
                                                value={searchQuery}
                                                onChange={this.handleInputChange}
                                                placeholder="Escribe y selecciona una opción..."
                                            />
                                            {showOptions && searchResults.length > 0 && (
                                                <ul className="autocomplete-options">
                                                    {searchResults.map((result, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => this.handleSelectOption(result)}
                                                            className="autocomplete-option"
                                                        >
                                                            <div><strong>
                                                                {result.merchantID}
                                                            </strong>
                                                            </div>
                                                            <div><strong>Nombre: </strong>
                                                                {result.username + " " + result.lastName + " " + result.secondLastName}
                                                            </div>
                                                            <div><strong>Negocio: </strong>
                                                                {result.merchant_name}
                                                            </div>

                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </Form.Group>
                                    </div>
                                    {/*  <div className="col">
                                        <Button className="btnSearch" variant="primary" onClick={this.searchClient.bind(this)} disabled={this.state.searchText.trim().length===0}>Buscar</Button>
                                    </div> */}
                                </div>
                                {(Object.keys(this.state.client).length <= 1 && this.state.searching === false) || (Object.keys(this.state.client).length > 1 && this.state.searching === true) ?
                                    <React.Fragment>


                                        <div className="row">
                                            <div className="col">
                                                {this.state.client.username !== undefined ?
                                                    <h5>Nombre: {this.state.client.username + " " + this.state.client.lastName + " " + this.state.client.secondLastName}</h5>
                                                    : <h5>Nombre:</h5>
                                                }
                                                <h5>Negocio: {this.state.client.merchantName}</h5>
                                                <h5>Dirección: {this.state.client.address}</h5>
                                            </div>
                                            <div className="col">
                                                <h5>Telefono:  {this.state.client.phone}</h5>
                                                <h5>Merchant ID:  {this.state.client.MerchantID}</h5>
                                                <h5>Merchant #:  {this.state.client.merchantNumber}</h5>
                                            </div>
                                            <div className="col">
                                                <h5>Ultima transacción:  {this.state.client.lastTransaction}</h5>
                                                <h5>Fecha Expiración:  {this.state.client.expirationDate}</h5>
                                            </div>
                                            <div className="col">
                                                <h5>Estado:  {this.state.client.status}</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {transactionsList !== undefined && (transactionsList.length > 0 || this.state.searching === false) ?
                                                <React.Fragment>
                                                    <Table responsive >
                                                        <thead className="tr1">
                                                            <tr>

                                                                <th scope="col">Record ID</th>
                                                                <th scope="col">Fecha</th>
                                                                <th scope="col">Hora</th>
                                                                <th scope="col">Transacción</th>
                                                                <th scope="col">Amount</th>
                                                                <th scope="col">Municipal (1%)</th>
                                                                <th scope="col">Estatal (10.5%)</th>
                                                                <th scope="col">Estatal (6%)</th>
                                                                <th scope="col">IVU</th>
                                                                <th scope="col">Machine ID</th>
                                                                <th scope="col">Software</th>
                                                                <th scope="col">Fecha de transacción</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {currentPageData.map((transaction, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{transaction.recordId}</td>
                                                                        <td>{transaction.fecha}</td>
                                                                        <td>{transaction.Hora}</td>
                                                                        <td>{transaction.transaccionType}</td>
                                                                        <td>{transaction.amount}</td>
                                                                        <td>{transaction.municipal}</td>
                                                                        <td>{transaction.estatal10}</td>
                                                                        <td>{transaction.estatal6}</td>
                                                                        <td>{transaction.ivu}</td>
                                                                        <td>{transaction.machineID}</td>
                                                                        <td>{transaction.software}</td>
                                                                        <td>{transaction.createdOn}</td>
                                                                    </tr>
                                                                )
                                                            })

                                                            }
                                                        </tbody>
                                                    </Table>
                                                    <ReactPaginate
                                                        pageCount={pageC}
                                                        onPageChange={this.handlePageChange}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        forcePage={this.state.currentPage} // Establece la página actual activa en el componente de paginación
                                                    />
                                                </React.Fragment> : null
                                            }
                                            {transactionsList.length <= 0 && this.state.searching ?

                                                <Alert variant="secondary" className="infoAlert">
                                                    No cuenta con actividad en los ultimos 90 días
                                                </Alert> : null

                                            }
                                        </div>
                                    </React.Fragment> : null
                                }
                                {Object.keys(this.state.client).length <= 1 && this.state.searching === true ?
                                    <Alert variant="warning" className="infoAlert">
                                        Su criterio de busqueda no coincidió con ningun registro
                                    </Alert> : null
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default Last90daysActivity;