import React from "react";
import Navbar from "../componentes/Navbar";
import ReactPaginate from 'react-paginate';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import AdminUserRegister from "./AdminUserRegister";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "../../node_modules/@fortawesome/free-solid-svg-icons"
import Alert from 'react-bootstrap/Alert';

class AdminUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            role: -1,
            token: "",
            currentPage: 0,
            perPage: 10, // Número de elementos por página
            pageCount: 0,
            loading: false,
            superUsers: []
        }
    }

    componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role: role })

        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/superUser/superUsers`,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true
        }
        this.setState({ loading: true })
        axios(config).then(response => {
            this.setState({ loading: false })
            if (response.status === 200) {
                var pageC = Math.ceil(response.data.length / this.state.perPage);
                this.setState({ superUsers: response.data, pageCount: pageC })
            }
        }).catch((error) => {
            this.setState({ loading: false })
            Swal.fire({
                title: "Ha ocurrido un error",
                text: "Por favor intentelo más tarde",
                icon: "error"
            })
        })
    }

    /**
     * Metodo utilizado para manejar la paginación de la tabla
     * @param {*} param0 Pagina seleccionada
     */
    handlePageChange = ({ selected }) => {
        this.setState({
            currentPage: selected,
            selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    updateListUsers = (user, update) => {
        if (update) {
            const nuevaLista = this.state.superUsers.map(objeto => {
                if (objeto.idUser === user.idUser) {
                    // Reemplazar el objeto con uno nuevo
                    return user;
                }
                // Mantener los objetos que no se están reemplazando
                return objeto;
            });
            this.setState({ superUsers: nuevaLista })
        } else {
            this.setState((prevState) => ({
                superUsers: [...prevState.superUsers, user]
            }))
        }


    }

    deleteUser = (idUser) => {
        Swal.fire({
            title: "¿Está seguro que desea eliminar el usuario?",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Aceptar",
            reverseButtons: true
        }).then((response) => {
            if (response.isConfirmed) {
                const config = {
                    method: 'delete',
                    url: global.URLBack + `/dashBoard/superUser/superUsers/` + idUser,
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    },
                    withCredentials: true
                }
                this.setState({ loading: true })
                axios(config).then(response => {
                    this.setState({ loading: false })
                    if (response.data.rta) {
                        Swal.fire({
                            title: "Usuario eliminado correctamente",
                            icon: "success"
                        })
                        const nuevaLista = this.state.superUsers.filter(usuario => usuario.idUser !== idUser);
                        this.setState({ superUsers: nuevaLista })
                    } else {
                        Swal.fire({
                            title: "Ocurrio un error tratanto de eliminar el usuario",
                            text: "Por favor intentelo más tarde",
                            icon: "error"
                        })
                    }
                }).catch((err) => {
                    this.setState({ loading: false })
                    Swal.fire({
                        title: "Ocurrio un error tratanto de eliminar el usuario",
                        text: "Por favor intentelo más tarde",
                        icon: "error"
                    })
                })
            }
        })
    }

    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;
        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }


    render() {
        const { currentPage, perPage, superUsers } = this.state;
        const offset = currentPage * perPage;
        var pageC = this.state.pageCount
        var currentPageData = []
        if (superUsers.length > 0) {
            currentPageData = superUsers.slice(offset, offset + perPage);
        } else {
            currentPageData = superUsers
        }

        return (
            <React.Fragment>
                {this.renderNavbar()}
                <div className='mainn'>
                    <div className="sonn1" style={{ height: "50px" }}>
                        <p className='titulo'><strong>Usuarios</strong></p>
                    </div>
                    <div className="sonn">
                        {this.state.loading ?
                            <div className="spinner"></div>
                            :
                            <React.Fragment>
                                {superUsers.length > 0 ?
                                    <Link to="./logHistory">
                                        <Button variant="secondary" className="m-3 mt-5" style={{ float: "right" }}>
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <svg
                                                        viewBox="0 0 1024 1024"
                                                        className="icon"
                                                        version="1.1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="#000000"
                                                    >
                                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                        <g id="SVGRepo_iconCarrier">
                                                            <path
                                                                d="M850.903995 414.630553h-0.974739V367.987457c0-65.413986-62.100693-126.406834-95.553074-159.147615l-7.470267-7.406787C648.55661 103.086822 607.179356 94.83533 573.138241 94.83533l-11.235094-0.06348H293.947238c-23.191003 0-39.365319 15.91425-39.365319 38.717199v129.78668h-82.302974c-42.09295 0-76.392084 34.233606-76.392084 76.392084V851.08727c0 41.251316 32.869791 74.769226 73.794487 76.196522 0.585662 0 0.974739 0.263139 1.559377 0.263139H850.902971c42.158478 0 76.455565-34.300158 76.455565-76.458637V491.022637c0.001024-42.096021-34.296063-76.392084-76.454541-76.392084z"
                                                                fill="#27323A"
                                                            ></path>
                                                            <path
                                                                d="M711.632041 236.707953l7.795863 7.72931c11.368199 11.171613 27.99712 27.671525 43.263251 46.316477-29.360936-9.029645-60.996944-11.108132-89.513175-12.279457-1.103748-20.331291-4.612603-57.358056-15.980802-91.850705 15.26613 12.601981 33.128834 28.775273 54.434863 50.084375z"
                                                                fill="#F4CE73"
                                                            ></path>
                                                            <path
                                                                d="M304.469707 144.660662h257.301359l11.238165 0.063481c5.783928 0 13.316653 0.066553 24.62137 4.094517l3.312269 3.442303c29.101893 28.775273 39.55781 100.687859 39.55781 142.065112 0 8.963092 7.01771 16.303326 15.980802 16.629946l6.106452 0.259043c44.237989 1.689411 99.320972 3.831379 132.449806 33.844529l0.652215 0.585663c2.597597 7.792791 4.546051 15.588654 4.546051 23.058921 0 0-0.066553 25.596109-0.066553 45.9274H565.474459c-39.950981 0-74.117011 54.238277-88.994065 54.238278H304.469707V144.660662z"
                                                                fill="#FFFFFF"
                                                            ></path>
                                                            <path
                                                                d="M188.323228 545.260915v306.541027c0 13.383205-5.325228 24.751404-21.695106 24.881437-14.421425-0.130033-20.853472-11.108132-20.853473-25.596109V339.667813c0-14.616987 11.89038-26.504296 26.504296-26.504295h82.302974v156.74558c-37.286832 5.066185-66.258691 36.702193-66.258691 75.351817z"
                                                                fill="#79CCBF"
                                                            ></path>
                                                            <path
                                                                d="M877.470747 851.08727c0 14.616987-11.949766 26.570848-26.566752 26.570848H234.250628c0.063481-0.325596 0.130033-0.522182 0.196586-0.781224 1.427296-7.403715 3.764826-14.032348 3.764826-22.539812V545.260915c0-14.613915 11.952837-26.504296 26.570848-26.504296h211.699554c25.203961 0 42.029469-15.588654 58.399347-30.724751 7.792791-7.2102 23.970179-22.217288 31.113827-23.51455h284.909403c14.616987 0 26.566753 11.886285 26.566752 26.504296V851.08727z"
                                                                fill="#F4CE73"
                                                            ></path>
                                                            <path
                                                                d="M776.719407 589.954533H571.775449c-11.949766 0-21.631625 9.678788-21.631625 21.631625 0 12.016318 9.68186 21.695106 21.631625 21.695106h204.944982c11.89038 0 21.568144-9.678788 21.568145-21.695106 0-11.953861-9.678788-21.631625-21.569169-21.631625zM776.719407 685.57416H571.775449c-11.949766 0-21.631625 9.678788-21.631625 21.631625 0 11.949766 9.68186 21.628554 21.631625 21.628554h204.944982c11.89038 0 21.568144-9.678788 21.568145-21.628554 0-11.953861-9.678788-21.631625-21.569169-21.631625z"
                                                                fill="#27323A"
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div className="col">
                                                    <h5>Historial de Cambios</h5>
                                                </div>
                                            </div>
                                        </Button>
                                    </Link> : null
                                }

                                <AdminUserRegister edit={false} updateList={this.updateListUsers}></AdminUserRegister>
                                {superUsers.length <= 0 ?
                                    <Alert variant="primary" className="mt-5">
                                        Aun no cuenta con usuarios registrados
                                    </Alert> :
                                    <React.Fragment>
                                        <Table responsive>

                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col">Nombre del usuario</th>
                                                    <th scope="col">Credenciales del usuario</th>
                                                    <th scope="col">Función</th>
                                                    <th scope="col">Editar</th>
                                                    <th scope="col">Eliminar</th>
                                                    <th scope="col">Cambios</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentPageData.map((user, index) => (
                                                    <tr key={user.idUser}>
                                                        <td>{user.userName + " " + user.lastName + " " + user.secondlastname}</td>
                                                        <td>{user.email}</td>
                                                        <td>{user.role === "ADMIN" ? "Administrador" : "Usuarios"}</td>
                                                        <td><AdminUserRegister edit={true} user={user} updateList={this.updateListUsers}></AdminUserRegister></td>
                                                        <td><div style={{ cursor: "pointer" }} onClick={() => this.deleteUser(user.idUser)}><FontAwesomeIcon icon={faTrash} style={{ color: "#d92626", }} /></div></td>
                                                        <td>
                                                            <Link to={`./logHistory/${user.idUser}`}>
                                                                <div style={{ cursor: "pointer" }}>
                                                                    <svg
                                                                        viewBox="0 0 1024 1024"
                                                                        width="25"
                                                                        height="25"
                                                                        className="icon"
                                                                        version="1.1"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="#000000"
                                                                    >
                                                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                                        <g id="SVGRepo_iconCarrier">
                                                                            <path
                                                                                d="M850.903995 414.630553h-0.974739V367.987457c0-65.413986-62.100693-126.406834-95.553074-159.147615l-7.470267-7.406787C648.55661 103.086822 607.179356 94.83533 573.138241 94.83533l-11.235094-0.06348H293.947238c-23.191003 0-39.365319 15.91425-39.365319 38.717199v129.78668h-82.302974c-42.09295 0-76.392084 34.233606-76.392084 76.392084V851.08727c0 41.251316 32.869791 74.769226 73.794487 76.196522 0.585662 0 0.974739 0.263139 1.559377 0.263139H850.902971c42.158478 0 76.455565-34.300158 76.455565-76.458637V491.022637c0.001024-42.096021-34.296063-76.392084-76.454541-76.392084z"
                                                                                fill="#27323A"
                                                                            ></path>
                                                                            <path
                                                                                d="M711.632041 236.707953l7.795863 7.72931c11.368199 11.171613 27.99712 27.671525 43.263251 46.316477-29.360936-9.029645-60.996944-11.108132-89.513175-12.279457-1.103748-20.331291-4.612603-57.358056-15.980802-91.850705 15.26613 12.601981 33.128834 28.775273 54.434863 50.084375z"
                                                                                fill="#F4CE73"
                                                                            ></path>
                                                                            <path
                                                                                d="M304.469707 144.660662h257.301359l11.238165 0.063481c5.783928 0 13.316653 0.066553 24.62137 4.094517l3.312269 3.442303c29.101893 28.775273 39.55781 100.687859 39.55781 142.065112 0 8.963092 7.01771 16.303326 15.980802 16.629946l6.106452 0.259043c44.237989 1.689411 99.320972 3.831379 132.449806 33.844529l0.652215 0.585663c2.597597 7.792791 4.546051 15.588654 4.546051 23.058921 0 0-0.066553 25.596109-0.066553 45.9274H565.474459c-39.950981 0-74.117011 54.238277-88.994065 54.238278H304.469707V144.660662z"
                                                                                fill="#FFFFFF"
                                                                            ></path>
                                                                            <path
                                                                                d="M188.323228 545.260915v306.541027c0 13.383205-5.325228 24.751404-21.695106 24.881437-14.421425-0.130033-20.853472-11.108132-20.853473-25.596109V339.667813c0-14.616987 11.89038-26.504296 26.504296-26.504295h82.302974v156.74558c-37.286832 5.066185-66.258691 36.702193-66.258691 75.351817z"
                                                                                fill="#79CCBF"
                                                                            ></path>
                                                                            <path
                                                                                d="M877.470747 851.08727c0 14.616987-11.949766 26.570848-26.566752 26.570848H234.250628c0.063481-0.325596 0.130033-0.522182 0.196586-0.781224 1.427296-7.403715 3.764826-14.032348 3.764826-22.539812V545.260915c0-14.613915 11.952837-26.504296 26.570848-26.504296h211.699554c25.203961 0 42.029469-15.588654 58.399347-30.724751 7.792791-7.2102 23.970179-22.217288 31.113827-23.51455h284.909403c14.616987 0 26.566753 11.886285 26.566752 26.504296V851.08727z"
                                                                                fill="#F4CE73"
                                                                            ></path>
                                                                            <path
                                                                                d="M776.719407 589.954533H571.775449c-11.949766 0-21.631625 9.678788-21.631625 21.631625 0 12.016318 9.68186 21.695106 21.631625 21.695106h204.944982c11.89038 0 21.568144-9.678788 21.568145-21.695106 0-11.953861-9.678788-21.631625-21.569169-21.631625zM776.719407 685.57416H571.775449c-11.949766 0-21.631625 9.678788-21.631625 21.631625 0 11.949766 9.68186 21.628554 21.631625 21.628554h204.944982c11.89038 0 21.568144-9.678788 21.568145-21.628554 0-11.953861-9.678788-21.631625-21.569169-21.631625z"
                                                                                fill="#27323A"
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <div className="d-flex justify-content-center">
                                            <ReactPaginate
                                                pageCount={pageC}
                                                onPageChange={this.handlePageChange}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                forcePage={this.state.currentPage}
                                            />
                                        </div>
                                    </React.Fragment>

                                }

                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment >

        )
    }

}

export default AdminUsers;