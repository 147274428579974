import React from "react";
import Navbar from "../componentes/Navbar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faExclamationTriangle,
} from "../../node_modules/@fortawesome/free-solid-svg-icons";
import "../elements/ResetPassword.css";
import "../componentes/GlobalVariables";
import axios from "axios";
import {
  MsjFail
} from "../elements/Forms";

const data = [
  {
    zipcode: "95963     ",
    isDefault: false,
    address: "AV 18 # 312",
    town: "ADJUNTAS                      ",
    typeAddress: "Negocio",
    address2: "AV 18 # 3",
    state: "STATE                         ",
    merchantnumber: "",
    idAddress: 4,
    nameoncard: "",
    securitycode: 0,
    creditcarnumber: "",
    service: "",
    fiscalterminals: 1,
    paymethod: "CREDIT-CARD",
    cardtype: "",
    expDateM: "",
    expDateY: "",
    routeNumber: "",
    accountNumber: "",
    accountNumber2: "",
  },
];

class UpdateAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      form: {
        id: "",
        tipo: "",
        direccion1: "",
        direccion2: "",
        ciudad: "",
        estado: "PR",
        zipCode: "",
      },
      res: {
        typeCard: [
          {
            id: 1,
            value: "Visa",
          },
        ],
        service: [
          {
            id: 1,
            value: "Ivu Basic[15.00/mount]",
          },
        ],
        towns: [
          {
            id: 1,
            value: "Adjuntas",
          },
        ],
        numTerm: [
          {
            id: 1,
            value: "20",
          },
        ],
      },
      expressiones: {
        name: /^[a-zA-ZÀ-ÿ\s]{3,40}$/, // Letras y espacios, pueden llevar acentos.
        email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        password: /^.{4,12}$/, // 4 a 12 digitos.
        user: /^[a-zA-Z0-9_-]+[a-zA-ZÀ-ÿ\s]{4,16}$/, // Letras, numeros, guion y guion_bajo

        merchantNumber: /^\d{11}$/,
       nameCard: /^[a-zA-ZÀ-ÿ\s]{10,40}$/,
        securityCode: /^\d{3}$/,
        zipCode: /^\d{5,14}$/,

        state: /^[a-zA-ZÀ-ÿ\s]{3,10}$/,
        routeNumber: /^\d{4,9}$/,
        accountNumber: /^\d{4,16}$/,
      },
      valid: null,
      validForm: null,
      bander: false,
      error: "",
      towns: [
        {
          id: 0,
          value: "",
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);

  }

  validation = () => {
    
    if (
      this.state.data.zipcode  === "" ||
      !this.state.expressiones.zipCode.test(this.state.data.zipcode)
    ) {
      this.setState({
        valid: false,
        error: "Código postal inválido.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.address  === ""
    ) {
      this.setState({
        valid: false,
        error:
          "La dirección no es válida.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    /* if (
      this.state.data.address2  === "" 
    ) {
      this.setState({
        valid: false,
        error:
        "La dirección 2 no es válida",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    } */
    return true;
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });

  };
  submitLogin(e) {
    const token = localStorage.getItem("loggedIvuUser");
    e.preventDefault();

    
    if (this.validation()) {
      this.setState({ validForm: true });

        const config = {
            method: "put",
            url: global.URLBack + `/dashBoard/address/updateAddress`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                typeAddress: this.state.data.typeAddress,
                address: this.state.data.address,
                address2: this.state.data.address2,
                town: this.state.data.town,
                state: this.state.data.state,
                zipcode: this.state.data.zipcode,
                idAddress: this.state.data.idAddress,
            },
            withCredentials: true,
        };
        
        axios(config)
        .then((res) => {
            /* this.setState({
                data: res.data.address,
            }); */
            window.history.back()

        })
        .catch((err) => {

        });

      
    } else {
      this.setState({ validForm: false });
    }


  }
  handleClick = e =>{
    e.preventDefault();
    window.history.back()
    
  };
  handleClick2 = e =>{
    e.preventDefault();
    window.location.reload()
    
  };

  async componentDidMount() {
    const token = localStorage.getItem("loggedIvuUser");
    const config = {
      method: "get",
      url: global.URLBack + `/query/register/loadTown`,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    await axios(config)
      .then((res) => {
        this.setState({
          towns: res.data.towns,
        });
      })
      .catch((err) => {

      });


    if (typeof this.props.match.params.id != "undefined") {
      
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
        method: "get",
        url:
          global.URLBack +
          `/dashBoard/address/getInfoAddress/` +
          this.props.match.params.id,
        withCredentials: true,
      };

      await axios(config)
        .then((res) => {
          if(res.data.error){
            window.location.replace("/dashboard")
          }
          this.setState({
            data: res.data.address,
        });
    })
    .catch((err) => {

    });
}
    
  }
  render() {
    return (
      <div>

       <div> <Navbar />
        <div id="container-rp" className="container">
          <section id="content-header-rp" className="content-header">
            <h1 id="h1-grid">
              Agregar Dirección
              <Breadcrumbs
                className="breadcrumbs"
                separator=">"
                aria-label="breadcrumb"
              >
                <Link className="link-rp" color="inherit" to="/dashboard">
                  <FontAwesomeIcon className="icon" icon={faTachometerAlt} />
                  Panel
                </Link>
                <Link
                  className="link-rp"
                  color="inherit"
                  onClick={this.handleClick}
                >
                  Mi dirección
                </Link>
                <Link
                  className="link-rp"
                  color="inherit"
                  onClick={this.handleClick2}
                >
                  Agregar dirección
                </Link>
              </Breadcrumbs>
            </h1>
          </section>
          
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Dirección</h5>
              <hr />
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label for="type">
                    <b>Tipo</b>
                  </label>
                  <select
                    onChange={this.handleChange}
                    name="typeAddress"                  
                    className="form-select"
                    id="type"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    value={
                        this.state.data.typeAddress
                    }
                    required
                  >
                    <option value="Negocio">Negocio</option>
                    <option value="Hogar">Hogar</option>
                    <option value="Otro">Otro</option>
                  </select>
                </div>
                <div className="form-group">
                  <label for="Direccion1">
                    <b>Dirección línea 1</b>
                  </label>
                  <input
                    onChange={this.handleChange}
                    name="address"
                    type="text"
                    className="form-control"
                    id="Direccion1"
                    placeholder="Dirección línea 1"
                    value={
                      this.props.match.params.id  === "undefined"
                        ? ""
                        : this.state.data.address
                    }
                    required
                  />
                </div>
                <div className="form-group">
                  <label for="Direccion2">
                    <b>Dirección línea 2</b>
                  </label>
                  <input
                    onChange={this.handleChange}
                    name="address2"
                    type="text"
                    className="form-control"
                    id="Direccion2"
                    placeholder="Dirección línea 2"
                    value={
                      this.props.match.params.id  === "undefined"
                        ? ""
                        : this.state.data.address2
                    }
                    required
                  />
                </div>
                <div className="form-group">
                  <label for="ciudad">
                    <b>Ciudad</b>
                  </label>
                  <select
                    onChange={this.handleChange}
                    name="town"
                    type="email"
                    className="form-select"
                    id="ciudad"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    value={
                      this.props.match.params.id  === "undefined"
                        ? ""
                        : this.state.data.town
                    }
                  >
                    {this.state.towns.map((town) => (
                      <option key={town.id} value={town.id}>
                        {town.value}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="Estado">
                    <b>Estado</b>
                  </label>
                  <input
                    onChange={this.state.data.state}
                    type="text"
                    className="form-control"
                    id="Estado"
                    name="estado"
                    placeholder="Estado"
                    value="PR"
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label for="Código Postal">
                    <b>Código Postal</b>
                  </label>
                  <input
                    onChange={this.handleChange}
                    type="text"
                    className="form-control"
                    id="Código Postal"
                    name="zipcode"
                    placeholder="Código Postal"
                    value={
                      this.props.match.params.id  === "undefined"
                        ? ""
                        : this.state.data.zipcode
                    }
                    required
                  />
                </div>
                {this.state.validForm  === false ? (
            <div id="error" className="alert-formAdd" >
            <MsjFail>
            <p className="error-message">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            {this.state.error}
            </p>
            </MsjFail>
          </div>
          ) : null}
                <button
                  id="btn-addAddress"
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.submitLogin.bind(this)}
                >
                  Actualizar locación
                </button>
              </form>
            </div>
          </div>
        </div>
        <footer>© 2021 IvuControlPR</footer>
        </div>
      </div>
    );
  }
}
export default UpdateAddress;
