import React from "react";
import Navbar from "../componentes/Navbar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faExclamationTriangle,
  faShoppingBasket,
} from "../../node_modules/@fortawesome/free-solid-svg-icons";
import "../elements/ResetPassword.css";
import "../componentes/GlobalVariables";
import axios from "axios";
import {
  Input,
  SelectPM,
  SelectService,
  SelectPhysiTer,
  Selectll,
  SelectSW,
  ContainerTerms,
  Label,
  MsjFail
} from "../elements/Forms";
import InputMask from "react-input-mask";
import "../styles.css";
import QR from "../img/athm_qrcode_sticker.png"



class FormAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        merchantnumber: "",
        address: "",
        typeAddress: "Negocio",
        address2: "",
        state: "PR",
        zipcode: "",
        nameoncard: "",
        securitycode: 0,
        creditcarnumber: "",
        terms: false,
        town: 1,
        service: "",
        software: "",
        fiscalterminals: 1,
        paymethod: "CREDIT-CARD",
        cardtype: "Visa",
        expDateM: "",
        expDateY: "",
        routeNumber: "",
        accountNumber: "",
        accountNumber2: "",
        accountNameBank: "",
        accountNumberBank: "",
        routeNumberBank: "",
      },
      sc: "",
      mc: "",
      res: {
        typeCard: [
          {
            "id": 1,
            "value": "Visa"
          }
        ],
        service: [
          {
            "id": 1,
            "value": "Ivu Basic[15.00/mount]"
          }
        ],
        year: null,
        towns: [
          {
            "id": 1,
            "value": "Adjuntas"
          }],
        software: [
          {
            id: 1,
            value: "Retail Manager",
          },
        ],
        numTerm: [
          {
            "id": 1,
            "value": "20"
          }
        ]
      },
      valid: null,
      //validUpdate: null,   
      validForm: null,
      loading: null,
      error: "",

      expressiones: {
        name: /^[a-zA-ZÀ-ÿ\s]{3,40}$/, // Letras y espacios, pueden llevar acentos.
        email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        password: /^.{4,12}$/, // 4 a 12 digitos.
        user: /^[a-zA-Z0-9_-]+[a-zA-ZÀ-ÿ\s]{4,16}$/, // Letras, numeros, guion y guion_bajo

        merchantNumber: /^\d{11}$/,

        nameCard: /^[a-zA-ZÀ-ÿ\s]{10,40}$/,
        nameCount: /^.+/,
        securityCode: /^\d{3}$/,
        zipCode: /^\d{5}$/,

        state: /^[a-zA-ZÀ-ÿ\s]{3,10}$/,
        routeNumber: /^\d{4,9}$/,
        accountNumber: /^\d{4,16}$/,
      },
      paymentMethods: []
    };
  }

  validation = () => {
    if (
      this.state.data.merchantnumber === "" ||
      !this.state.expressiones.merchantNumber.test(this.state.data.merchantnumber)
    ) {
      this.setState({
        valid: false,
        error:
          "El número de registro de comerciante debe ser máximo de 11 dígitos.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (this.state.mc === "") {
      this.setState({
        valid: false,
        error: "Debe insertar un certificado de registro de comerciante",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.address === ""
    ) {
      this.setState({
        valid: false,
        error:
          "La dirección no es válida",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    /* if (
      this.state.data.address2  === "" 
    ) {
      this.setState({
        valid: false,
        error:
        "La dirección 2 no es válida",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    } */
    if (
      this.state.data.zipcode === "" ||
      !this.state.expressiones.zipCode.test(this.state.data.zipcode)
    ) {
      this.setState({
        valid: false,
        error: "Debe ingresar un código postal de 5 dígitos.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.state === "" ||
      !this.state.expressiones.state.test(this.state.state)
    ) {
      this.setState({
        valid: false,
        error: "Debe ingresar un estado válido.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (this.state.data.paymethod === "CREDIT-CARD") {
      if (
        this.state.data.nameoncard === "" ||
        !this.state.expressiones.nameCard.test(this.state.data.nameoncard)
      ) {
        this.setState({
          valid: false,
          error: "El nombre en la tarjeta debe tener de 10 a 20 letras",
        });
        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.securitycode === "" ||
        !this.state.expressiones.securityCode.test(this.state.data.securitycode)
      ) {
        this.setState({
          valid: false,
          error:
            "El código de seguridad solo puede contener un máximo de 3 dígitos.",
        });
        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.creditcarnumber === ""
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar un número de tarjeta válido.",
        });

        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.expDateM === "" || parseInt(this.state.res.month) > parseInt(this.state.data.expDateM) && parseInt(this.state.res.year) >= parseInt(this.state.data.expDateY) || this.state.data.expDateM > '12'
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar una fecha de expiración válida.",
        });

        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.expDateY === "" || parseInt(this.state.res.year) > parseInt(this.state.data.expDateY) || parseInt(this.state.data.expDateY) > (parseInt(this.state.res.year) + 46)
      ) {

        this.setState({
          valid: false,
          error: "Debe ingresar una fecha de expiración válida.",
        });

        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
    }
    else if (this.state.data.paymethod === "BANK-ACCOUNT") {
      if (this.state.data.accountNameBank === "" || !this.state.expressiones.nameCount.test(this.state.data.accountNameBank)) {
        this.setState({
          valid: false,
          error: "El nombre en la tarjeta debe tener de 10 a 40 letras",
        });
        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (this.state.data.accountNumberBank === "" || !this.state.expressiones.accountNumber.test(this.state.data.accountNumberBank)) {
        this.setState({
          valid: false,
          error: "Debe ingresar un número de cuenta válido.",
        });
        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (this.state.data.routeNumberBank === "" || !this.state.expressiones.routeNumber.test(this.state.data.routeNumberBank)) {
        this.setState({
          valid: false,
          error: "Debe ingresar un número de ruta válido",
        });
        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
    }

    if (!this.state.data.terms) {
      this.setState({
        valid: false,
        error: "Aceptar términos y condiciones",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    return true;
  };

  handleChange = (e) => {
    let value = e.target.type === "file" ? e.target.files[0] :
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: value,
      },
    });
    if (e.target.name === "merchantnumber") {
      const config = {
        method: 'get',
        url: global.URLBack + `/query/register/checkMerchantNumber/` + e.target.value,
        headers: {
          'Content-Type': 'text/plain'
        },
      }

      axios(config)
        .then(res => {


          if (res.data.res === false) {
            this.setState({
              validForm: false,
              error: "Número de registro de comerciante existente."
            })
          }
          else {
            this.setState({
              validForm: null,
              error: ""
            })
          }

        }).catch(err => {

        })
    }
    if (e.target.name === 'creditcarnumber') {

      switch (e.target.value.substring(0, 1)) {
        case '5':
          this.setState({
            data: {
              ...this.state.data,
              cardtype: 'Masrter Card',
              creditcarnumber: e.target.value
            }
          })
          break;
        case '4':
          this.setState({
            data: {
              ...this.state.data,
              cardtype: 'Visa',
              creditcarnumber: e.target.value
            }
          })
          break;
        default:
      }
    }
  };

  handleClick = e => {
    e.preventDefault();
    this.props.history.push("/dashboard/address/" + this.props.match.params.id);
  };
  handleClick2 = e => {
    e.preventDefault();
    window.location.reload()

  };
  /*handleButton = (e) => {
    e.preventDefault();
    window.ATHM_Checkout.onCompletedPayment = (response) =>
    {       
    const token = localStorage.getItem("loggedIvuUser");
    e.preventDefault();        
      const config = {
        method: "post",
        url: global.URLBack + `/dashBoard/address/addAddress/` + this.props.match.params.id,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: JSON.stringify(this.state.data),
        withCredentials: true,
      };

      axios(config)
        .then((res) => {  
          //window.history.back()        
          this.props.history.push("/dashboard/address/" + this.props.match.params.id);
        })
        .catch((err) => {
          if(err.response.data.msg){
            this.setState({
              loading:false,
              error: err.response.data.msg,
              validForm:false
            })
          }
          else{
            this.setState({
              loading:false,
              error: "Número de registro de comerciante existente",
              validForm:false
            })
          }
    
        });          
    };
    window.ATHM_Checkout.onCancelledPayment = (response) => {
       console.log('onCancelledPayment', response)
      // window.canPay = response
    };
    window.ATHM_Checkout.onExpiredPayment= (response) => {
      // console.log('onExpiredPayment', response)
      // window.expirePay = response
    }
  }*/


  submitLogin(e) {

    const token = localStorage.getItem("loggedIvuUser");
    e.preventDefault();

    if (this.validation()) {
      this.setState({ loading: true, validForm: true });


      const config = {
        method: "post",
        url: global.URLBack + `/dashBoard/address/addAddress/` + this.props.match.params.id,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: JSON.stringify(this.state.data),
        withCredentials: true,
      };
      const formData = new FormData();
      formData.append("SC", this.state.sc);
      formData.append("MC", this.state.mc);
      axios(config)
        .then((res) => {

          const files = {
            method: "post",
            url:
              global.URLBack +
              `/dashBoard/business/saveForms/` + res.data.idAddress, //AQUI
            headers: {
              "Content-Type": "multipart/form-data",
              'Authorization': 'Bearer ' + token,
            },
            data: formData,
            withCredentials: true,
          };
          axios(files)
            .then((res) => {
              this.props.history.push("/dashboard/address/" + this.props.match.params.id);
            }).catch((err) => {

              this.setState({
                loading: false,
                validForm: false,
                error: "Ha ocurrido un error en el registro, inténtelo de nuevo"
              })
            });
        })
        .catch((err) => {
          if (err.response.data.msg) {
            this.setState({
              loading: false,
              error: err.response.data.msg,
              validForm: false
            })
          }
          else {
            this.setState({
              loading: false,
              error: "Número de registro de comerciante existente",
              validForm: false
            })
          }

        });

    } else {

      this.setState({ validForm: false });
    }

  }

  async componentDidMount() {
    const token = localStorage.getItem("loggedIvuUser");
    const configAut = {
      method: "get",
      url: global.URLBack + `/dashBoard/address/isOwnBusiness/` + this.props.match.params.id,
      headers: {
        Authorization: "Bearer " + token,
      },
    }

    axios(configAut)
      .then((res) => {
        if (res.data.error) {
          window.location.replace("/dashboard")
        }
      })
      .catch((err) => {

      });

    const config = {
      method: "get",
      url: global.URLBack + `/query/register/loadData/3`,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    await axios(config)
      .then((res) => {

        this.setState({
          res: res.data,
        });
        this.setState({
          data: {
            ...this.state.data,
            service: res.data.service[0].value,
            software: res.data.software[0].value,
          }

        })
        const config3 = {
          method: 'get',
          url: global.URLBack + `/dashBoard/superUser/paymentMethods`,
        }

        axios(config3).then(response2 => {
          this.setState({ paymentMethods: response2.data })
        }).catch(error => {
        })
      })
      .catch((err) => {

      });

  }
  /*componentDidUpdate(pp,prevState,ss){
    if( this.state.data.paymethod == "ATHMOVIL"){      
    const paymentData = document.createElement('script');
      let data = document.createTextNode(`ATHM_Checkout = {
        env: 'sandbox',
        publicToken: 'sandboxtoken01875617264',
        timeout: 500,
        theme: 'btn',
        lang: 'en',
        total: '${this.state.data.service == "Ivu Basic[15.00/mount]" ? 15.00 * parseInt(this.state.data.fiscalterminals) : 99.00 * parseInt(this.state.data.fiscalterminals)}',
        tax: 0.00,
        subtotal: 0.00,
        metadata1: 'test',
        metadata2: 'metadata2 test',
        items: [
          {
            "name": "${this.state.data.service}",
            "description": "Número de terminales fiscales: ${this.state.data.fiscalterminals}",
            "price": "${this.state.data.service == "Ivu Basic[15.00/mount]" ? 15.00 * parseInt(this.state.data.fiscalterminals) : 99.00 * parseInt(this.state.data.fiscalterminals)}",
            "metadata": "metadata test"
          }
      ],
      onCancelledPayment: function (response) {
        // console.log('onCancelledPayment', response)
        // window.canPay = response
      },
      onExpiredPayment: function (response) {
        // console.log('onExpiredPayment', response)
        // window.expirePay = response
      }
    }`
    )
    paymentData.async = true;
    paymentData.appendChild(data);
    const script = document.createElement('script');
    const jQueryData = document.createElement('script');
    script.src = `https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js`;
    jQueryData.src = `https://www.athmovil.com/api/js/v2/athmovilV2.js`;
    script.async = true;
    jQueryData.async = true;
    document.body.appendChild(script);
    document.body.appendChild(jQueryData);
    document.body.appendChild(paymentData);

    if(prevState.data !== this.state.data){
      console.log("hola")
      if (
        this.state.data.merchantnumber  === "" ||
        !this.state.expressiones.merchantNumber.test(this.state.data.merchantnumber)
      ) {
        this.setState({
          valid: false,
          error:
            "El número de registro de comerciante debe ser máximo de 11 dígitos.",
        });
        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.address  === ""
      ) {
        this.setState({
          valid: false,
          error:
            "La dirección no es válida",
        });
        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      // if (
        this.state.data.address2  === "" 
      ) {
        this.setState({
          valid: false,
          error:
          "La dirección 2 no es válida",
        });
        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      //} 
      if (
        this.state.data.zipcode  === "" ||
        !this.state.expressiones.zipCode.test(this.state.data.zipcode)
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar un código postal de 5 dígitos.",
        });
        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.state  === "" ||
        !this.state.expressiones.state.test(this.state.state)
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar un estado válido.",
        });
        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (this.state.data.paymethod  === "CREDIT-CARD") {
        if (
          this.state.data.nameoncard  === "" ||
          !this.state.expressiones.nameCard.test(this.state.data.nameoncard)
        ) {
          this.setState({
            valid: false,
            error: "El nombre en la tarjeta debe tener de 10 a 20 letras",
          });
          return this.setState({validUpdate: false});
        } else {
          this.setState({
            valid: true,
            error: "",
          });
        }
        if (
          this.state.data.securitycode  === "" ||
          !this.state.expressiones.securityCode.test(this.state.data.securitycode)
        ) {
          this.setState({
            valid: false,
            error:
              "El código de seguridad solo puede contener un máximo de 3 dígitos.",
          });
          return this.setState({validUpdate: false});
        } else {
          this.setState({
            valid: true,
            error: "",
          });
        }
        if (
          this.state.data.creditcarnumber  === "" 
        ) {
          this.setState({
            valid: false,
            error: "Debe ingresar un número de tarjeta válido.",
          });
  
          return this.setState({validUpdate: false});
        } else {
          this.setState({
            valid: true,
            error: "",
          });
        }
        if (
          this.state.data.expDateM  === ""|| parseInt(this.state.res.month)> parseInt(this.state.data.expDateM) && parseInt(this.state.res.year)>= parseInt(this.state.data.expDateY)  || this.state.data.expDateM > '12'
        ) {
          this.setState({
            valid: false,
            error: "Debe ingresar una fecha de expiración válida.",
          });
  
          return this.setState({validUpdate: false});
        } else {
          this.setState({
            valid: true,
            error: "",
          });
        }
        if (
          this.state.data.expDateY  === "" || parseInt(this.state.res.year)> parseInt(this.state.data.expDateY) || parseInt(this.state.data.expDateY) > (parseInt(this.state.res.year) + 46)
        ) {
          
          this.setState({
            valid: false,
            error: "Debe ingresar una fecha de expiración válida.",
          });
  
          return this.setState({validUpdate: false});
        } else {
          this.setState({
            valid: true,
            error: "",
          });
        }
      }
      else if (this.state.data.paymethod  === "BANK-ACCOUNT"){
        if (this.state.data.accountNameBank === "" || !this.state.expressiones.nameCard.test(this.state.data.accountNameBank)) {
          this.setState({
            valid: false,
            error: "El nombre en la tarjeta debe tener de 10 a 40 letras",
          });
          return this.setState({validUpdate: false});
        } else {
          this.setState({
            valid: true,
            error: "",
          });
        }
        if (this.state.data.accountNumberBank === "" || !this.state.expressiones.accountNumber.test(this.state.data.accountNumberBank)) {
          this.setState({
            valid: false,
            error: "Debe ingresar un número de cuenta válido.",
          });
          return this.setState({validUpdate: false});
        } else {
          this.setState({
            valid: true,
            error: "",
          });
        }
        if (this.state.data.routeNumberBank === "" || !this.state.expressiones.routeNumber.test(this.state.data.routeNumberBank)) {
          this.setState({
            valid: false,
            error: "Debe ingresar un número de ruta válido",
          });
          return this.setState({validUpdate: false});
        } else {
          this.setState({
            valid: true,
            error: "",
          });
        }
      }
  
      if (!this.state.data.terms) {
        this.setState({
          valid: false,
          error: "Aceptar términos y condiciones",
        });
        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      return this.setState({validUpdate: true});
  }
  console.log(this.state.validUpdate)
}
}*/

  handleFiles = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0]
    })
  }

  render() {
    return (
      <div>

        <div> <Navbar />
          <div id="container-rp" className="container">
            <section id="content-header-rp" className="content-header">
              <h1 id="h1-grid">
                Agregar Dirección
                <Breadcrumbs
                  className="breadcrumbs"
                  separator=">"
                  aria-label="breadcrumb"
                >
                  <Link className="link-rp" color="inherit" to="/dashboard">
                    <FontAwesomeIcon className="icon" icon={faTachometerAlt} />
                    Panel
                  </Link>
                  <Link
                    className="link-rp"
                    color="inherit"
                    onClick={this.handleClick}
                  >
                    Mi dirección
                  </Link>
                  <Link
                    className="link-rp"
                    color="inherit"
                    onClick={this.handleClick2}
                  >
                    Agregar dirección
                  </Link>
                </Breadcrumbs>
              </h1>
            </section>

            <div className={this.state.loading ? 'son-hidden' : 'card'}>
              <div className="card-body">
                {this.state.loading ? <div className="spinner"></div> : null}
                <h5 className="card-title">Dirección </h5>
                <hr />
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label for="type">
                      <b>Tipo</b>
                    </label>
                    <select
                      onChange={this.handleChange}
                      name="typeAddress"
                      type="email"
                      className="form-select"
                      id="type"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"

                      required
                    >
                      <option value="Negocio">Negocio</option>
                      <option value="Hogar">Hogar</option>
                      <option value="Otro">Otro</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label for="merchantnumber">
                      <b>Número de registro del Comerciante *</b>
                    </label>
                    <InputMask className="form-control inputCcNumber"
                      onChange={this.handleChange}
                      name="merchantnumber"
                      type="text"
                      mask="99999999999"
                      placeholder="Registro del Comerciante"
                    />
                  </div>
                  <div className="container-files-fa">

                    <div className="form-group-files">
                      <label for="sc">
                        <b>Formulario SC2916</b>
                      </label>
                      <input type="file" id="file1" name="sc" placeholder="Seleccionar archivo"
                        onChange={this.handleFiles} />
                    </div>
                    <div className="form-group-files">

                      <label for="mc">
                        <b>Certificado de Registro de Comerciante *</b>
                      </label>
                      <input type="file" id="file2" name="mc" placeholder="Seleccionar archivo"
                        onChange={this.handleFiles} />
                    </div>
                  </div>
                  <div form-control="form-group">
                    <label for="Direccion1">
                      <b>Dirección línea 1 *</b>
                    </label>
                    <input
                      onChange={this.handleChange}
                      name="address"
                      type="text"
                      className="form-control"
                      id="Direccion1"
                      placeholder="Dirección línea 1"

                      required
                    />
                  </div>
                  <div className="form-group">
                    <label for="Direccion2">
                      <b>Dirección línea 2</b>
                    </label>
                    <input
                      onChange={this.handleChange}
                      name="address2"
                      className=" text form-control"
                      id="Direccion2"
                      placeholder="Dirección línea 2"

                      required
                    />
                  </div>
                  <div className="form-group">
                    <label for="ciudad">
                      <b>Ciudad *</b>
                    </label>
                    <select
                      onChange={this.handleChange}
                      name="town"
                      type="email"
                      className="form-select"
                      id="ciudad"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"

                    >
                      {this.state.res.towns.map((town) => (
                        <option key={town.id} value={town.id}>
                          {town.value}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label for="Estado">
                      <b>Estado</b>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Estado"
                      name="estado"
                      placeholder="Estado"
                      value="PR"
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label for="Código Postal">
                      <b>Código Postal *</b>
                    </label>
                    <input
                      onChange={this.handleChange}
                      type="text"
                      className="form-control"
                      id="Código Postal"
                      name="zipcode"
                      placeholder="Código Postal"

                      required
                    />
                  </div>
                  <div className="form-group">
                    <label><b>Servicio / Terminales Físicas *</b></label>
                    <div>
                      <FontAwesomeIcon icon={faShoppingBasket}></FontAwesomeIcon>

                      <SelectService onChange={this.handleChange} name="service">
                        {this.state.res.service.map((s) => (
                          <option key={s.id} value={s.value}>
                            {s.value}
                          </option>
                        ))}
                      </SelectService>
                      <SelectPhysiTer
                        onChange={this.handleChange}
                        name="fiscalterminals"
                      >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>13</option>
                        <option>14</option>
                        <option>15</option>
                        <option>16</option>
                        <option>17</option>
                        <option>18</option>
                        <option>19</option>
                        <option>20</option>
                      </SelectPhysiTer>
                    </div>
                  </div>
                  <Label>
                    Software *
                    <div>
                      <SelectSW onChange={this.handleChange} name="software">
                        {this.state.res.software.map((Software) => (
                          <option key={Software.id} value={Software.value}>
                            {Software.value}
                          </option>
                        ))}
                      </SelectSW>
                    </div>
                  </Label>
                  <div className="form-group">
                    <Label>
                      Método de pago *
                      <SelectPM onChange={this.handleChange} name="paymethod">
                        {this.state.paymentMethods.filter(method => method.status === true).map((method, index) => (
                          <option key={index} value={method.code}>{method.name}</option>
                        ))
                        }
                      </SelectPM>
                      {this.state.data.paymethod === "ATHMOVIL" ? (<div id="form-group-ath-movil-formAddress">
                        <label className="QR_text">
                          Escanea el código QR para proceder con tu pago.
                        </label>
                        <label>
                          <img width="400vw" height="500vh" src={QR} alt="QR de Ath Movil" />
                        </label>
                      </div>
                      ) : null}
                    </Label>
                  </div>
                  {this.state.data.paymethod === "CREDIT-CARD" ? (
                    <div id="form-group-credit-card">
                      <Label>
                        Nombre en la tarjeta *
                        <Input
                          onChange={this.handleChange}
                          name="nameoncard"
                          type="text"
                          placeholder="Nombre en la tarjeta"
                        />
                      </Label>
                      <Label>
                        Número Tarjeta Crédito/ Tipo de tarjeta *
                        <div className="DivForm">
                          <InputMask className="inputCcNumber"
                            onChange={this.handleChange}
                            name="creditcarnumber"
                            type="text"
                            placeholder="Número"
                            mask="9999-9999-9999-9999"
                          />

                          <Selectll onChange={this.handleChange} name="cardtype" disabled value={this.state.data.cardtype}>
                            {this.state.res.typeCard.map(s => (
                              <option key={s.value} value={s.value}>
                                {s.value}
                              </option>
                            ))}
                          </Selectll>
                        </div>
                      </Label>
                      <Label>
                        Código de seguridad CVV2 *
                        <InputMask className="inputMask"
                          onChange={this.handleChange}
                          name="securitycode"
                          type="text"
                          mask="999"
                          placeholder="Código de seguridad"
                        />
                      </Label>
                      <Label>
                        {" "}
                        Fecha de Expiración- Mes/Año *
                        <div>
                          <InputMask
                            onChange={this.handleChange}
                            name="expDateM"
                            type="text"
                            min="01"
                            max="12"
                            mask="99"
                            placeholder="MM"
                          /> / <InputMask
                            onChange={this.handleChange}
                            name="expDateY"
                            type="text"
                            placeholder="YYYY"
                            mask="9999"
                          />

                        </div>
                      </Label>{" "}
                    </div>
                  ) : null}
                  {this.state.data.paymethod === "BANK-ACCOUNT" ? (
                    <div id="form-group-debitcard">
                      <Label>
                        Nombre de cuenta *
                        <Input
                          onChange={this.handleChange}
                          name="accountNameBank"
                          type="text"
                          placeholder="Nombre de cuenta"
                        />
                      </Label>
                      <Label>
                        Número de cuenta *
                        <InputMask className="inputMask"
                          onChange={this.handleChange}
                          name="accountNumberBank"
                          type="text"
                          placeholder="Número de cuenta"
                          mask="9999999999999999"
                        />
                      </Label>
                      <Label>
                        Número de ruta *
                        <InputMask className="inputMask"
                          onChange={this.handleChange}
                          name="routeNumberBank"
                          type="text"
                          placeholder="Número de ruta"
                          mask="999999999"
                        />
                      </Label>
                    </div>
                  ) : null}
                  <ContainerTerms>
                    <Label>
                      <input
                        type="checkbox"
                        name="terms"
                        onClick={this.handleChange}
                        defaultChecked={this.state.data.terms}
                      />
                      <Link to="/terms">Acepto los términos y condiciones de uso</Link>

                    </Label>
                  </ContainerTerms>

                  <button
                    id="btn-addAddress"
                    type="button"
                    className="btn btn-primary"
                    onClick={this.submitLogin.bind(this)}
                  >
                    Agregar locación
                  </button>
                  {this.state.validForm === false && this.state.error !== '' ? (
                    <div id="error" className="alert-formAdd" >
                      <MsjFail>
                        <p className="error-message">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                          {this.state.error}
                        </p>
                      </MsjFail>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
          <footer>© 2021 IvuControlPR</footer>
          <div></div></div>
      </div>
    );
  }
}
export default FormAddress;