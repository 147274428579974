import React from "react";
import Navbar from "../componentes/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Banner from "../componentes/Carousel";
import img1 from "../img/pos_retailmanager.jpg"
import img2 from "../img/pos_softwreinterface.jpg"
import img3 from "../img/pos_clover.jpg"
import img4 from "../img/pos_pax.jpg"
import img5 from "../img/pos_pda.jpg"
import img6 from "../img/terminalFiscal.png"
import { faMobileAlt, faEnvelope, faMapMarkerAlt } from "../../node_modules/@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "../../node_modules/@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import '../elements/Navbar.css'
import '../elements/Home.css'
import axios from "axios";
import { Carousel, Card } from 'react-bootstrap';




class Home extends React.Component {
  state = {
    services: [],
    colors: ['#24b339', '#2596be', '#4169E1', '#FF4500', '#8B008B']
  };




  handleClick = e => {

    global.button = true;

  }

  componentDidMount() {
    const config = {
      method: 'get',
      url: global.URLBack + `/dashBoard/service`
    }

    axios(config).then(response => {
      if (Number(response.status) === 200) {
        this.setState({ services: response.data })
      }
    })
  }

  render() {
    global.button = false;
    return (
      <div>
        <Navbar />
        <Banner />
        <div id="div-content-h" className="content">


          <div
            id="div-container-h"
            className="container"
          >
            <div className="fila">
              {/* <div className="col-md-6 col-sm-12">
               <div className="card">
                <div id="div-card1-header-h" className="card-header" > <h5>Ivu Basico</h5> <h6 >1 mes de servicio</h6> </div>
                <div className="card-body">
                  <p className="card-text">
                    Transacciones ilimitadas
                  </p>
                  <hr/>
                  <p className="card-text">
                    Transacciones ilimitadas
                  </p>
                  <hr/>
                  <p className="card-text">
                    Soporte 24/7
                  </p>
                  <hr/>
                  <p className="card-text">
                   Integración con Clover
                  </p>
                  <hr/>
                  <p className="card-text">
                   Integración con RetailManagerPR
                  </p>
                  <hr/>
                  <p className="card-text">
                    Soporte de configuración
                  </p>
                  <hr/>
                  <p className="card-text">
                    Precio: <span id="span-h" className="pull-right"> $15.00/mes</span>
                  </p>
                  <hr/>
                  <NavLink id="link-basic-h" to="/register" className="btn btn-primary">
                    Seleccionar
                  </NavLink>
                </div> 
              </div> 
            </div> */}
              <Carousel className="w-100 mx-auto" variant="dark">
                {this.state.services.filter(card => card.active === true).map((card, index) => (
                  <Carousel.Item key={index}>
                    <div className="d-flex justify-content-center align-items-center h-100 mb-5">
                      <div className="col-md-6">
                        <div className="card">
                          <div style={{ backgroundColor: this.state.colors[index] }} id="div-card2-header-h" className="card-header">
                            <h5>{card.serviceName}</h5>
                            <h6>{card.duration} días de servicio</h6>
                          </div>
                          <div className="card-body">
                            <p className="card-text">Transacciones ilimitadas</p>
                            <hr />
                            <p className="card-text">Transacciones ilimitadas</p>
                            <hr />
                            <p className="card-text">Soporte 24/7</p>
                            <hr />
                            <p className="card-text">Integración con Clover</p>
                            <hr />
                            <p className="card-text">Integración con RetailManagerPR</p>
                            <hr />
                            <p className="card-text">Soporte de configuración</p>
                            <hr />
                            <p className="card-text">Precio: <span className="pull-right">$ {card.serviceValue}</span></p>
                            <hr />
                            <Link onClick={this.handleClick}
                              id="link-pro-h"
                              to={{
                                pathname: '/register',
                                state: { serviceV: card.serviceName }
                              }} className="btn btn-primary">
                              Seleccionar
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              {/* <div className="col-md-6">
                <div className="card">
                  <div id="div-card2-header-h" className="card-header"> <h5 >Ivu Pro</h5> <h6>1 año de servicio</h6></div>
                  <div className="card-body">
                    <p className="card-text">
                      Transacciones ilimitadas
                    </p>
                    <hr />
                    <p className="card-text">
                      Transacciones ilimitadas
                    </p>
                    <hr />
                    <p className="card-text">
                      Soporte 24/7
                    </p>
                    <hr />
                    <p className="card-text">
                      Integración con Clover
                    </p>
                    <hr />
                    <p className="card-text">
                      Integración con RetailManagerPR
                    </p>
                    <hr />
                    <p className="card-text">
                      Soporte de configuración
                    </p>
                    <hr />
                    <p className="card-text">
                      Precio: <span className="pull-right"> $99.00/año</span>
                    </p>
                    <hr />
                    <Link onClick={this.handleClick} id="link-pro-h" to='/register' className="btn btn-primary">
                      Seleccionar
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-md-12"><hr /></div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h4 id="h4-col-h">Sistemas POS compatibles</h4>
              </div>
              <div className="col-md-6">
                <img src={img1} alt="img1" width="100%"></img>
              </div>
              <div className="col-md-6">
                <img src={img2} alt="img2" width="100%"></img>
              </div>
              <div className="row">
                <div className="col-md-12"> <br /></div>
              </div>
              <div className="col-md-4">
                <img src={img3} alt="img3" width="100%"></img>
              </div>
              <div className="col-md-4">
                <img src={img4} alt="img4" width="100%"></img>
              </div>
              <div className="col-md-4">
                <img src={img5} alt="img5" width="100%"></img>
              </div>
            </div>
            <div id="div-hr-h" className="row">
              <div className="col-md-12"><hr /></div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <img src={img6} width="100%" alt="img6"></img>
              </div>
              <div id="div-col-md-6-h" className="col-md-6">
                <h3>¿Necesitas ayuda con tu sistema de punto de venta?</h3>
                <p>¿Hacienda te está volviendo loco? ¿No sabes qué hacer? Visita <a href="http://www.terminalfiscalivu.com/" target="_blank" rel="noreferrer">TerminalFiscalIvu.com</a>, tienen una solución que te hará cumplir con todos los requisitos. No pierda su tiempo, la solución que necesita está aquí.</p>
                <ul>
                  <li>Terminal Fiscal</li>
                  <li>Clientes</li>
                  <li>Cuentas por pagar, cuentas por cobrar</li>
                  <li>layway, WIC</li>
                  <li>Informes - IVU</li>
                  <li>Nómina de sueldos</li>
                </ul>
                Para obtener una lista de todas las funciones y opciones, visite: <a href="http://www.terminalfiscalivu.com/" target="_blank" rel="noreferrer">www.TerminalFiscalIvu.com</a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12"><hr /></div>
            </div>
            <div className="row">
              <div id="div-contact-us-h" className="col-md-6">
                <h3>Contacta con nosotros</h3>
                <FontAwesomeIcon icon={faMobileAlt} />
                787-466-2091
                <br />
                <FontAwesomeIcon icon={faMobileAlt} />
                787-396-2525
                <br />
                <FontAwesomeIcon icon={faEnvelope} />
                <a href="mailto:info@retailmanagerpr.com"> info@retailmanagerpr.com </a>
                <br />
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                Ave Andalucia 601 , San Juan PR 00920
              </div>
              <div className="col-md-6">
                <div className="mapouter"><div className="gmap_canvas"><iframe width="400" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=18%C2%B024'38.3%22N%2066%C2%B004'45.5%22W&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div></div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          © 2021 IvuControlPR
        </footer>
      </div>
    );
  }
}

export default Home;
